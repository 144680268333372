import styled from "styled-components/macro";
import { ScrollTop } from "../components/ScrollTop";
import { ScrollBottom } from "../components/ScrollBottom";

export const Lol = () => {
  const Section = styled.section`
    padding: 0rem 2rem;

    h3 {
      margin-bottom: 20px;
    }

    .menu {
      margin-bottom: 100px;
    }

    .misc-container {
      display: flex;
      margin-bottom: 40px;
    }

    .img-wrapper {
      position: relative;
      margin-right: 15px;
      img {
        height: 50px;
        width: 50px;
        border-radius: 7px;
        border: 2px solid #3e4042;
        box-sizing: border-box;
      }
      .badge {
        position: absolute;
        color: white;
        background: #a30f0f;
        padding: 1px 1px;
        top: 38px;
        right: 25px;
        display: inline-block;
        font-size: 11px;
      }
    }
  `;

  return (
    <>
      <ScrollBottom />
      <Section className="content-section">
        <h2>
          <strong>LoL Season 2024 New Items and Changes</strong>
        </h2>

        <h3>Contents:</h3>

        <ul className="menu">
          <li>
            <a href="#marksman-items">Marksman Items</a>
          </li>
          <li>
            <a href="#fighter-items">Fighter Items</a>
          </li>
          <li>
            <a href="#enchanter-items">Enchanter Items</a>
          </li>
          <li>
            <a href="#tank-items">Tank Items</a>
          </li>
          <li>
            <a href="#assassin-items">Assassin Items</a>
          </li>
          <li>
            <a href="#mage-items">Mage Items</a>
          </li>
          <li>
            <a href="#support-items">Support Items</a>
          </li>
        </ul>

        <h3>
          <a id="marksman-items" href="#marksman-items">
            Marksman Items and Changes Season 14
          </a>
        </h3>
        <p>
          Removed Items and Changes:&nbsp;<strong>Galeforce, Rageknife</strong>
        </p>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_9pCb55-0b/1x.webp" alt="Terminus" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Terminus</strong>
            </p>
            <ul>
              <li>Total Cost: 3200</li>
              <li>Item Recipe: Recurve Bow + Last Whisper + Dagger + 750 gold</li>
              <li>40 Attack Damage</li>
              <li>30% Attack Speed</li>
              <li>
                Passive – <strong>Shadow</strong>: Attacks apply 30 magic damage on-hit.
              </li>
              <li>
                Passive – <strong>Juxtaposition</strong>: Alternate between Light and Dark on-hits each attack. Light attacks grant 3-5 armor and magic resist (up to 15-25) for 5 seconds. Dark attacks
                grant 6% armor penetration and magic penetration (up to 30%) for 5 seconds
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_MVqr_XT1x/1x.webp" alt="experimental hexplate" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Experimental Hexplate</strong>
            </p>
            <ul>
              <li>Total Cost: 3000</li>
              <li>Item Recipe: Tunneler + Noonquiver + 600 gold</li>
              <li>55 Attack Damage</li>
              <li>20% Attack Speed</li>
              <li>300 Health</li>
              <li>
                Passive – <strong>Hexcharged</strong>: Gain 30 Haste for your Ultimate
              </li>
              <li>
                Passive – <strong>Overdrive</strong>: After casting your ultimate, gain 35% Attack Speed and 15% bonus Movement Speed for 7 seconds
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/guardian-angel/1x.webp"
              alt="Guardian Angel"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Guardian Angel</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>
                Item Recipe: B.F. Sword + Steel Sigil + 950 gold <strong>(Note: Stopwatch has been removed)</strong>
              </li>
              <li>55 Attack Damage</li>
              <li>45 Armor</li>
              <li>
                <strong>Passive </strong>– <strong>Rebirth</strong>: Upon taking lethal damage, restores 50% of base health and 100% of maximum mana after 4 seconds of stasis. 300 second cooldown.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/phantom-dancer/1x.webp"
              alt="Phantom Dancer"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Phantom Dancer</strong>
            </p>
            <ul>
              <li>Total Cost: 2800</li>
              <li>Item Recipe: Hearthbound Axe + Cloak of Agility + 1000 gold</li>
              <li>20 Attack Damage</li>
              <li>30% Attack Speed</li>
              <li>20% Critical Strike Chance</li>
              <li>10% Movement Speed</li>
              <li>
                Passive – <strong>Spectral Waltz</strong>: On-attack, basic attacks grant ghosting and a stack of 7% bonus attack speed up to 5 stacks (maximum 35% bonus attack speed).
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/infinity-edge/1x.webp"
              alt="Infinity Edge"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Infinity Edge</strong>
            </p>
            <ul>
              <li>Total Cost: 3300</li>
              <li>Item Recipe: B.F. Sword + Pickaxe + Cloak of Agility + 525 gold</li>
              <li>65 Attack Damage</li>
              <li>20% Critical Strike Chance</li>
              <li>45% Critical Strike Damage</li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/navori-quickblades/1x.webp"
              alt="Navori Quickblades"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Navori Quickblades</strong>
            </p>
            <ul>
              <li>Total Cost: 3300</li>
              <li>Item Recipe: Caulfield's Warhammer + B. F. Sword + Cloak of Agility + 300 gold</li>
              <li>60 Attack Damage</li>
              <li>20% Critical Strike Chance</li>
              <li>15 Ability Haste</li>
              <li>
                Passive - <strong>Transcendence</strong>: Your Attacks reduce your non-Ultimate Ability cooldowns by 12% of their remaining cooldown.
              </li>
              <li>
                Passive - <strong>Impermanence</strong>: Your abilities&nbsp; deal up to 0% = (20% Critical Strike Chance) increased damage based on Critical Strike Chance
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/kraken-slayer/1x.webp"
              alt="Kraken Slayer"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Kraken Slayer</strong>
            </p>
            <ul>
              <li>Total Cost: 3000</li>
              <li>Item Recipe: Noonquiver + Cloak of Agility + Recurve Bow + 400 gold</li>
              <li>40 Attack Damage</li>
              <li>35% Attack Speed</li>
              <li>20% Critical Strike Chance</li>
              <li>
                Passive - <strong>Bring It Down</strong>: Every third Attack deals 76 = (35-85 based on champion level + 65% AD + 60% AP) bonus physical damage. Additional triggers on the same target
                within 6 seconds increased this damage by 50% up to 151 = (70-170 based on champion level + 130% AD + 120% AP) damage.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/the-collector/1x.webp"
              alt="The Collector"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>The Collector</strong>
            </p>
            <ul>
              <li>Total Cost: 3000</li>
              <li>Item Recipe: Serrated Dirk + Pickaxe + Cloak of Agility + 425 gold</li>
              <li>60 Attack Damage</li>
              <li>20% Critical Strike Chance</li>
              <li>16 Lethality</li>
              <li>
                Passive - <strong>Death</strong>: Your damage executes champions that are below 5% Health.
              </li>
              <li>
                Passive - <strong>Taxes</strong>: Champion kills grant an additional 25 gold.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/guinsoos-rageblade/1x.webp"
              alt="Guinsoo's Rageblade"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Guinsoo's Rageblade</strong>
            </p>
            <ul>
              <li>Total Cost: 3000</li>
              <li>Item Recipe: Amplifying Tome + Hearthbound Axe + Long Sword + 1050 gold</li>
              <li>30 Attack Damage</li>
              <li>30 Ability Power</li>
              <li>25% Attack Speed</li>
              <li>
                Passive - <strong>Wrath</strong>: Attacks apply 30 magic damage on-hit.
              </li>
              <li>
                Passive - <strong>Seething Strike</strong>: Basic attacks grant 8% Attack SPeed, stacking up to 4 times for a maximum of 32% = (32%) Attack Speed. While fully stacked, every third
                Attack applies your on-hit effects twice.
              </li>
            </ul>
          </div>
        </div>

        <h3>
          <a id="fighter-items" href="#fighter-items">
            Fighter Items and Changes Season 14
          </a>
        </h3>
        <p>
          Removed Items and Changes
          <strong>: Goredrinker, Sunderer, Whip, and Silvermere Dawn </strong>
          are removed.<strong> Hydra </strong>and <strong>Tiamat </strong>are reverted back to Cleave and Active.
        </p>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_RSInHKwok/1x.webp" alt="sky" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Sundered Sky</strong>
            </p>
            <ul>
              <li>Total Cost: 3100 gold</li>
              <li>Item Recipe: Tunneler + Caulfield’s Warhammer + 900 gold</li>
              <li>55 Attack Damage</li>
              <li>15 Ability Haste</li>
              <li>300 Health</li>
              <li>
                Passive – <strong>Lightshield Strike</strong>: The first attack against a champion will critically strike for 150% damage and heals for 110% base AD +8% of missing health.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/tiamat/1x.webp"
              alt="Tiamat"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Tiamat (Regained Active)</strong>
            </p>
            <ul>
              <li>Total Cost: 1200 gold</li>
              <li>Item Recipe: Long Sword + Long Sword + 500 gold</li>
              <li>20 Attack Damage</li>
              <li>
                Passive – <strong>Cleave</strong>: Attacks deal 40% AD (melee) / 20% AD (ranged) damage to other units within 450 units of the target hit.
              </li>
              <li>
                Active – <strong>Crescent</strong>: Deal 60% physical damage to enemies around you. 10 second cooldown.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/ravenous-hydra/1x.webp"
              alt="Ravenous Hydra"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Ravenous Hydra (Regained Active)</strong>
            </p>
            <ul>
              <li>Total Cost: 3300 gold</li>
              <li>Item Recipe: Tiamat + Caulfield’s Warhammer + Vampiric Scepter + 100 gold</li>
              <li>70 Attack Damage</li>
              <li>20 Ability Haste</li>
              <li>10 Lifesteal</li>
              <li>
                Active – <strong>Ravenous Crescent</strong>: Deal 80% total Attack Damage physical damage to nearby enemies. This damage applies Lifesteal. 10 second cooldown.
              </li>
              <li>
                Passive – <strong>Cleave</strong>: Attacks deal 40% AD (melee) / 20% AD (ranged) damage to other units within 450 units of the target hit.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/titanic-hydra/1x.webp"
              alt="Titanic Hydra"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Titanic Hydra</strong>
            </p>
            <ul>
              <li>Total Cost: 3300 gold</li>
              <li>Item Recipe: Tiamat + Tunneler + Ruby Crystal + 600 gold</li>
              <li>55 Attack Damage</li>
              <li>550 Health</li>
              <li>
                Passive – <strong>Cleave</strong>: Attacks deal additional physical damage on-hit and deal physical damage to enemies behind the target.
              </li>
              <li>
                Active – <strong>Titanic Crescent</strong>: On your next attack, Cleave will instead deal more physical damage in a larger area.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_VB9F0YE1_/1x.webp" alt="nitro" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Nitro Hexaegis</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Tunneler + Noonquiver + 600 gold</li>
              <li>55 Attack Damage</li>
              <li>20% Attack Speed</li>
              <li>300 Health</li>
              <li>Passive: Gain 30 ultimate ability haste.</li>
              <li>Passive: After casting your ultimate, gain 35% attack speed and 15% bonus movement speed for 7 seconds</li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_U0Sl0hSut/1x.webp" alt="tunneler" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Tunneler</strong>
            </p>
            <ul>
              <li>Total Cost: 1100 gold</li>
              <li>Item Recipe: Long Sword + Ruby Crystal + 350 gold</li>
              <li>15 Attack Damage</li>
              <li>250 Health</li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/hullbreaker/1x.webp"
              alt="Hullbreaker"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Hullbreaker</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Tunneler + Nether Shard + Pickaxe + 125 gold</li>
              <li>65 Attack Damage</li>
              <li>350 Health</li>
              <li>5% Movement Speed</li>
              <li>
                Passive –<strong> Skipper</strong>: Basic attacks grant a stack (up to a maximum of 5 stacks). Attacking an enemy champion or epic monster while at max stacks consumers all stacks to
                deal 150% base AD bonus physical damage, increased to 400% against structures.
              </li>
              <li>
                Passive – <strong>Boarding Part</strong>: Nearby allied Siege and Super Minions gain 15-90 bonus armor and magic resistance (based on level).
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/stridebreaker/1x.webp"
              alt="Stridebreaker"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Stridebreaker</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Phage + Pickaxe + Dagger + 725 gold</li>
              <li>50 Attack Damage</li>
              <li>20% Attack Speed</li>
              <li>400 Health</li>
              <li>
                Active – <strong>Halting Slash</strong>: Slow enemies in a radius centered around you by 25% and gain 25% bonus movement speed per champion hit that decays over 2 seconds. Can move
                while casting. 15-second cooldown.
              </li>
              <li>
                Passive – <strong>Heroic Gait</strong>: Dealing physical damage grants you 20 bonus movement speed for 2 seconds.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/wits-end/1x.webp"
              alt="Wit's End"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Wit’s End</strong>
            </p>
            <ul>
              <li>Total Cost: 2900 gold</li>
              <li>Item Recipe: Recurve Bow + Negatron Cloak + Dagger + 1000 gold</li>
              <li>50% Attack Speed</li>
              <li>50 Magic Resist</li>
              <li>20% Tenacity</li>
              <li>
                Passive – <strong>Fray</strong>: Attacks apply 15 magic damage on-hit.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/trinity-force/1x.webp"
              alt="Trinity Force"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Trinity Force</strong>
            </p>
            <ul>
              <li>Total Cost: 3133 gold</li>
              <li>Item Recipe: Sheen + Phage + Hearthbound Axe + 33 gold</li>
              <li>50 Attack Damage</li>
              <li>33% Attack Speed</li>
              <li>300 Health</li>
              <li>10 Ability Haste</li>
              <li>
                Passive – <strong>Spellblade</strong>: After using an Ability, your next Attack is enhanced with an additional 114 = (200% base physical damage). 1.5 seconds cooldown.
              </li>
              <li>
                Passive – <strong>Quicken</strong>: Attacking a unit grants 20 Move Speed for 2 seconds.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/black-cleaver/1x.webp"
              alt="Black Cleaver"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Black Cleaver</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Kindlegem + Caulfield’s Warhammer + Long Sword + 750 gold</li>
              <li>55 Attack Damage</li>
              <li>400 Health</li>
              <li>15 Ability Haste</li>
              <li>
                Passive – <strong>Carve</strong>: Dealing physical damage to a champion applies a stack of 4% Armor reduction for 6 seconds (max 24% Armor reduction).
              </li>
              <li>
                Passive – <strong>Furror</strong>: Attacking a unit grants 20 Move Speed for 2 seconds.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/spear-of-shojin/1x.webp"
              alt="Spear of Shojin"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Spear of Shojin</strong>
            </p>
            <ul>
              <li>Total Cost: 3100 gold</li>
              <li>Item Recipe: Kindlegem + Caulfield’s Warhammer + Pickaxe + 325 gold</li>
              <li>
                Passive – <strong>Dragonforce</strong>: Your non-ultimate spells gain 15 Ability Haste
              </li>
              <li>
                Passive – <strong>Focused Will</strong>: Spell hits grant stacks, up to 3. Your spells deal 3% increased damage for each stack (up to 9%).
              </li>
              <li>Item performance will differ for melee and ranged users.</li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/phage/1x.webp"
              alt="Phage"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Phage</strong>
            </p>
            <ul>
              <li>Total Cost: 1100 gold</li>
              <li>Item Recipe: Ruby Crystal + Long Sword + 350 gold</li>
              <li>
                Passive – <strong>Rage</strong>: Attacking a unit grants 20 movement speed for 2 seconds.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_vsCYzRJb_/1x.webp" alt="steel sigil" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Steel Sigil</strong>
            </p>
            <ul>
              <li>Total Cost: 1200 gold</li>
              <li>Item Recipe: Long Sword + Cloth Armor + 550 gold</li>
              <li>15 Attack Damage</li>
              <li>30 Armor</li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/deaths-dance/1x.webp"
              alt="Death's Dance"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Death's Dance</strong>
            </p>
            <ul>
              <li>Total Cost: 3200 gold</li>
              <li>Item Recipe: Steel Sigil + Caulfield’s Warhammer + 900 gold</li>
              <li>55 Attack Damage</li>
              <li>15 Ability Haste</li>
              <li>40 Armor</li>
              <li>
                Passive -<strong> Ignore Pain</strong>: 30% melee = (30%) of damage taken is dealt to you over 3 seconds instead.
              </li>
              <li>
                Passive - <strong>Defy</strong>: When a champion that you have damaged within the last 3 seconds dies, cleanse <strong>Ignore Pain's</strong> remaining damage pool and heal 3 = (50%
                bonus AD) Health over 2 seconds. Item performance differs for melee and ranged users.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/blade-of-the-ruined-king/1x.webp"
              alt="Blade of the Ruined King"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Blade of The Ruined King</strong>
            </p>
            <ul>
              <li>Total Cost: 3200 gold</li>
              <li>Item Recipe: Vampiric Scepter + Recurve Bow + Pickaxe + 725 gold</li>
              <li>40 Attack Damage</li>
              <li>25% Attack Speed</li>
              <li>8% Life Steal</li>
              <li>
                Passive -<strong>Mist's Edge</strong>: Attacks apply an additional 12% = (12%) enemy current Health physical damage on-hit. Maximum<strong> Mist's Edge</strong> damage dealt to minions
                and jungle monsters is 60.
              </li>
              <li>
                Passive - <strong>Clawind Shadows</strong>: Your first basic attack against an enemy champion slows them by 30% = (30%) for 1 second. 15-second cooldown. Item performance differs for
                melee and ranged users.
              </li>
            </ul>
          </div>
        </div>
        <h3>
          <a id="enchanter-items" href="#enchanter-items">
            Enchanter Items and Changes Season 14
          </a>
        </h3>
        <p>
          Removed Items and Changes: <strong>Chemtech Putrifier, Chalice of Harmony</strong>
        </p>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_ZC6iQmJ9E/1x.webp" alt="mote" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Glowing Mote</strong>
            </p>
            <ul>
              <li>Cost: 250 gold</li>
              <li>5 Ability Haste</li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_s_-vkZcBQ/1x.webp" alt="dawncore" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Dawncore</strong>
            </p>
            <ul>
              <li>Total Cost: 2700 gold</li>
              <li>Item Recipe: Bandleglass Mirror + Bandleglass Mirror + 700 gold</li>
              <li>40 Ability Power</li>
              <li>20 Ability Haste</li>
              <li>150% Mana Regeneration</li>
              <li>
                Passive – <strong>Dawncore</strong>: Gain 3% Heal and Shield Power and 5 Ability Power per 100% Base Mana Regeneration.
              </li>
              <li>
                Passive – <strong>Summoner Spell Haste</strong>: Gain 18 Summoner Spell Haste.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/echoes-of-helia/1x.webp"
              alt="Echoes of Helia"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Echoes of Helia</strong>
            </p>
            <ul>
              <li>Total Cost: 2200 gold</li>
              <li>Item Recipe: Kindlegem + Bandleglass Mirror + 400 gold</li>
              <li>200 Health</li>
              <li>40 Ability Power</li>
              <li>20 Ability Haste</li>
              <li>125% Mana Regeneration</li>
              <li>
                Passive – <strong>Soul Siphon</strong>: Damaging a champion grants a Soul Shard, up to a maximum of 3. Healing or Shielding an ally consumes all Soul Shards and restores 20 Health and
                deals 55 magic damage per Shard to the nearest enemy champion.
              </li>
              <li>
                Passive – <strong>Dissonance removed</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/staff-of-flowing-water/1x.webp"
              alt="Staff of Flowing Water"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Staff of Flowing Water</strong>
            </p>
            <ul>
              <li>Total Cost: 2300 gold</li>
              <li>Item Recipe: Fiendish Codex + Forbidden Idol + 600 gold</li>
              <li>40 Ability Power</li>
              <li>15 Ability Haste</li>
              <li>125% Mana Regeneration</li>
              <li>8% Heal and Shield Power</li>
              <li>
                Passive – <strong>Rapids</strong>: Healing or Shielding allied champions (excluding the user) grants the user 40 Ability Power and 10% bonus Movement Speed over 3 seconds.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/ardent-censer/1x.webp"
              alt="Ardent Censer"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Ardent Censer</strong>
            </p>
            <ul>
              <li>Total Cost: 2300 gold</li>
              <li>Item Recipe: Aether Wisp + Forbidden Idol + 650 gold</li>
              <li>50 Ability Power</li>
              <li>8% Heal and Shield Power</li>
              <li>125% Base Mana Regen</li>
              <li>8% Move Speed</li>
              <li>
                Passive – <strong>Sanctify</strong>: Healing or Shielding an ally ehances you both for 6 seconds, granting 25% Attack Speed and 20 magic damage on-hit.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/imperial-mandate/1x.webp"
              alt="Imperial Mandate"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Imperial Mandate</strong>
            </p>
            <ul>
              <li>Total Cost: 2300 gold</li>
              <li>Item Recipe: Fiending Codex + Bandleglass Mirror + 650 gold</li>
              <li>60 Ability Power</li>
              <li>20 Ability Haste</li>
              <li>125% Base Mana Regen</li>
              <li>
                Passive – <strong>Coordinated Fire</strong>: Slowing or Immobilizing an enemy champion marks them for 5 seconds. Ally champion damage detonates the mark, dealing magic damage equal to
                12% of current health and granting you both 25% Move Speed for 2 seconds. 9-second cooldown per enemy champion.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/moonstone-renewer/1x.webp"
              alt="Moonstone Renewer"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Moonstone Renewer</strong>
            </p>
            <ul>
              <li>Total Cost: 2200 gold</li>
              <li>Item Recipe: Kindlegem + Bandleglass Mirror + 400 gold</li>
              <li>30 Ability Power</li>
              <li>250 Health</li>
              <li>20 Ability Haste</li>
              <li>
                Passive – <strong>Starlit Grace</strong>: Healing or shielding an ally chains to the nearest ally champion (excluding yourself), healing 40% or shielding 45% of the original amount. If
                there are no nearby allies, heal 30% or shield 35% of the original amount to the same target instead.
              </li>
            </ul>
          </div>
        </div>

        <h3>
          <a id="tank-items" href="#tank-items">
            Tank Items and Changes Season 14
          </a>
        </h3>
        <p>
          Removed Items and Changes<strong>:</strong> <strong>Turbo Chemtank, Radiant Virtue, Evenshroud, Gargoyle’s Stoneplate, Aegis of the Legion, Lifewell Pendant</strong>
        </p>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_-EfO5FH7Z/1x.webp" alt="hollow" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Hollow Radiance</strong>
            </p>
            <ul>
              <li>Total Cost: 2800 gold</li>
              <li>Item Recipe: Bami’s Cinder + Spectre’s Cowl + 550 gold</li>
              <li>600 Health</li>
              <li>40 Magic Resistance</li>
              <li>100% Base Health Regeneration</li>
              <li>
                Passive – <strong>Immolate</strong>: Taking or dealing damage causes you to begin dealing magic damage per second to nearby enemies (increased by 25% against minions) for 3 seconds.
                Taking or dealing damage refreshes the duration of this effect.
              </li>
              <li>
                Passive – <strong>Moonburn</strong>: Killing an enemy (non-ward, non-structure) deals 30 (+3.5% bonus HP) magic damage in an area around them.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_HhqTrZCz2/1x.webp" alt="despair" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>&nbsp;Unending Despair</strong>
            </p>
            <ul>
              <li>Total Cost: 2800 gold</li>
              <li>Item Recipe: Chain Vest + Kindlegem + Ruby Crystal + 800 gold</li>
              <li>400 Health</li>
              <li>55 Armor</li>
              <li>10 Ability Haste</li>
              <li>
                <strong>Passive</strong>: While in combat with champions, every 7 seconds, deal magic damage to nearby enemy champions, healing for 175% of the damage dealt.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_ZW8xzp5cU/1x.webp" alt="rookern" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Kaenic Rookern</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Spectre’s Cowl + Negatron Cloak + 850 gold</li>
              <li>350 Health</li>
              <li>80 Magic Resistance</li>
              <li>100% Base Health Regeneration</li>
              <li>
                Passive – <strong>Magebane</strong>: After not taking damage from champions for 15 seconds, gain a magic shield for 18% of your maximum health.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_2A7ZEiL6P/1x.webp" alt="trailblazer" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Trailblazer</strong>
            </p>
            <ul>
              <li>Total Cost: 2400 gold</li>
              <li>Item Recipe: Winged Moonplate + Chain Vest + 800 gold</li>
              <li>250 Health</li>
              <li>40 Armor</li>
              <li>5% Move Speed</li>
              <li>
                Passive – <strong>Lead the Way</strong>: While moving, gain 20 bonus Move Speed. At max speed, create a trail that speeds up allied champion's Move Speed by 15% of yours. Your next
                Attack discharges the bonus Move Speed and Melee Champions Slow the target by 50% for 1 second.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/jaksho-the-protean/1x.webp"
              alt="Jak'Sho, The Protean"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Jak’Sho the Protean</strong>
            </p>
            <ul>
              <li>Total Cost: 3200 gold</li>
              <li>Item Recipe: Chain Vest + Negatron Cloak + Ruby Crystal + 1100 gold</li>
              <li>200 Health</li>
              <li>50 Armor</li>
              <li>50 Magic Resistance</li>
              <li>
                Passive – <strong>Voidborn Resilience</strong>: For each second in champion combat gain a stack, up to a maximum of 5 stacks. At maximum stacks become empowered, increasing your bonus
                resists by 25% until end of combat.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/dead-mans-plate/1x.webp"
              alt="Dead Man's Plate"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Dead Man’s Plate</strong>
            </p>
            <ul>
              <li>Total Cost: 3100 gold</li>
              <li>Item Recipe: Winged Moonplate + Chain Vest + Ruby Crystal + 1100 gold</li>
              <li>300 Health</li>
              <li>45 Armor</li>
              <li>5% Movement Speed</li>
              <li>
                Passive – <strong>Shipwrecker</strong>: While moving, build up to 40 bonus Move Speed. Your next Attack discharges built up Move Speed to deal up to 40 (+100% base AD) bonus physical
                damage.
              </li>
              <li>
                Passive – <strong>Unsinkable</strong>: The strength of movement slowing effects is reduced by 25%.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/force-of-nature/1x.webp"
              alt="Force of Nature"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Force of Nature</strong>
            </p>
            <ul>
              <li>Total Cost: 2800 gold</li>
              <li>Item Recipe: Negatron Cloak + Winged Moonplate + Ruby Crystal + 700 gold</li>
              <li>400 Health</li>
              <li>50 Magic Resistance</li>
              <li>5% Movement Speed</li>
              <li>
                Passive – <strong>Absorb</strong>: Taking magic damage from enemy Champions grants a stack of Steadfast (up to a maximum of 8) for 7 seconds. Enemy Immobilizing effects grant an
                additional 2 stacks. One spell can add a new stack of Steadfast every 1 second.
              </li>
              <li>
                <strong>Dissipate</strong>: While at 8 stacks of Steadfast, gain 60 Magic Resist and 10% increased Movement Speed.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/warmogs-armor/1x.webp"
              alt="Warmog's Armor"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Warmog's Armor</strong>
            </p>
            <ul>
              <li>Total Cost: 3100 gold</li>
              <li>Item Recipe: Giant's Belt + Winged Moonplate + Crystalline Bracer + 600 gold</li>
              <li>750 Health</li>
              <li>5% Movement Speed</li>
              <li>200% Base Health Regen</li>
              <li>
                Passive – <strong>Warmog's Heart</strong>: If you have at least 1300 bonus Health, restore 31 = (5% maximum health) Health per second and gain 10% Move Speed if damage hasn't been
                taken within 6 seconds (3 seconds for non-Champions).
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/heartsteel/1x.webp"
              alt="Heartsteel"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Heartsteel</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Giant's Belt + Crystalline Bracer + Ruby Crystal + 900 gold</li>
              <li>800 Health</li>
              <li>200% Base Health Regen</li>
              <li>
                Passive – <strong>Colossal Consumption</strong>: Charge up a powerful attack against a champion over 3 seconds while within 700 range of them. The charged attack deals 131 = (100 + 5%
                maximum Health) bonus physical damage, and grants you 16 = (12+0.6% maximum Health) permanent max Health. 30-second cooldown per target.
              </li>
              <li>
                Passive - <strong>Goliath</strong>: For each 100 max Health, gain 5% increased size, up to 40%
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/sunfire-aegis/1x.webp"
              alt="Sunfire Aegis"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Sunfire Aegis</strong>
            </p>
            <ul>
              <li>Total Cost: 2700 gold</li>
              <li>Item Recipe: Bami's Cinder + Chain Vest + 900 gold</li>
              <li>450 Health</li>
              <li>50 Armor</li>
              <li>
                Passive – <strong>Immolate</strong>: Taking or dealing damage causes you to being dealing 12 = (12 + 1.75% bonus max Health) magic damage per second to nearby enemies (increased by 25%
                against minions) for 3 seconds. Damaging champions or epic jungle monsters with this effect adds a stack, increasing subsequent Immolate damage by 10% for 5 seconds (max stacks 6).
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/randuins-omen/1x.webp"
              alt="Randuin's Omen"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Randuin's Omen</strong>
            </p>
            <ul>
              <li>Total Cost: 2700 gold</li>
              <li>Item Recipe: Warden's Mail + Giant's Belt + 800 gold</li>
              <li>400 Health</li>
              <li>55 Armor</li>
              <li>
                Passive – <strong>Rock Solid</strong>: Reduce incoming damage from Attacks by up to 7 = (5 + 0.35% max Health), capped at 20% of the Attack's damage.
              </li>
              <li>
                Passive - <strong>Resilience</strong>: Critical Strikes deal 30% less damage to you.
              </li>
              <li>
                Active - <strong>Humility</strong>: Briefly Slow nearby enemies by 55% for 2 seconds. 60-second cooldown.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/iceborn-gauntlet/1x.webp"
              alt="Iceborn Gauntlet"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Iceborn Gauntlet</strong>
            </p>
            <ul>
              <li>Total Cost: 2700 gold</li>
              <li>Item Recipe: Sheen + Ruby Crystal + Chain Vest + 500 gold</li>
              <li>200 Health</li>
              <li>50 Armor</li>
              <li>
                Passive – Spellblade: After using an Ability, your next Attack is enhanced with an additional 58 = (100% base AD) physical damage and creates a frost field for 2 seconds. Enemies that
                move across the field are Slowed by (15% = (15% + 0.004% max Health)| 9% = (7.5% + 0.002% max Health)). 1.5-second cooldown.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/frozen-heart/1x.webp"
              alt="Frozen Heart"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Frozen Heart</strong>
            </p>
            <ul>
              <li>Total Cost: 2300 gold</li>
              <li>Item Recipe: Warden's Mail + Glacial Buckler + 400 gold</li>
              <li>70 Armor</li>
              <li>400 Mana</li>
              <li>20 Ability Haste</li>
              <li>
                Passive – <strong>Winter's Caress</strong>: Reduce the Attack Speed of nearby enemies by 20%.
              </li>
              <li>
                Passive - <strong>Rock Solid</strong>: Reduce incoming damage from Attacks by up to 7 = (5 + 0.35% max Health), capped at 20% of the Attack's damage.
              </li>
            </ul>
          </div>
        </div>

        <h3>
          <a id="assassin-items" href="#assassin-items">
            Assassin Items and Changes Season 14
          </a>
        </h3>
        <p>
          Removed Items and Change
          <strong>s: Duskblade of Draktharr, Prowler’s Claw</strong>
        </p>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_onIDO-Pk1/1x.webp" alt="voltaic" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Voltaic Cyclosword</strong>
            </p>
            <ul>
              <li>Total Cost: 2900 gold</li>
              <li>Item Recipe: The Brutalizer + Kircheis Shard + 863 gold</li>
              <li>55 Attack Damage</li>
              <li>18 Lethality</li>
              <li>15 Ability Haste</li>
              <li>
                Passive – <strong>Energized</strong>: Moving and Attacking will generate an Energized Attack. Dashes and Stealth stack Energized 75% faster.
              </li>
              <li>
                Passive – <strong>Firmament</strong>: Your Energized Attack applies 100 bonus physical damage and Slows enemies for 99% for 0.75 seconds. Slow is a melee only.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_jZaOEMwzc/1x.webp" alt="hydra" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Profane Hydra</strong>
            </p>
            <ul>
              <li>Total Cost: 3400 gold</li>
              <li>Item Recipe: Tiamat + The Brutalizer + 863 gold</li>
              <li>60 Attack Damage</li>
              <li>18 Lethality</li>
              <li>20 Ability Haste</li>
              <li>
                Active – <strong>Heretical Slash</strong>: Deal 65% total Attack Damage physical damage to nearby enemies. This damage increases to 97.5% total Attack Damage physical damage to enemies
                below 30% health.
              </li>
              <li>
                Passive – <strong>Cleave</strong>: Attacks deal 40% AD (melee) / 20% AD (ranged) damage to other units within 450 units of the target hit.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_vKAag0i-A/1x.webp" alt="zoomies" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Zoomies</strong>
            </p>
            <ul>
              <li>Total Cost: 2700 Gold</li>
              <li>Item Recipe: Serrated Dirk + Rectrix + 800 gold</li>
              <li>55 Attack Damage</li>
              <li>18 Lethality</li>
              <li>5% Move Speed</li>
              <li>
                Passive - <strong>Murder: </strong>After being out of combat with Champions for 8 seconds gain /Bonus Lethality/. This Lethality lasts for 3 seconds after dealing damage to champions.
              </li>
              <li>
                Passive - <strong>Murderspeed</strong>: If a champion dies within three seconds of damaging them, gain 150 decaying Move Speed for 1.5 seconds.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_RqfG8jU_m/1x.webp" alt="hubris" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Hubris</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Serrated Dirk + Caulfield’s Warhammer + 900 gold</li>
              <li>60 Attack Damage</li>
              <li>18 Lethality</li>
              <li>15 Ability Haste</li>
              <li>
                Passive – <strong>Ego</strong>: When you kill an enemy champion you are granted a statue of yourself.
              </li>
              <li>
                Passive – <strong>Eminence</strong>: When a champion that you have damaged within the last 3 seconds dies, gain 10 (+1 per Statue) Attack Damage for 60 seconds.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_ID7xiOR98/1x.webp" alt="opportunity" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Opportunity</strong>
            </p>
            <ul>
              <li>Total Cost: 2700 gold</li>
              <li>Item Recipe: Serrated Dirk + Nether Shard + 800 gold</li>
              <li>55 Attack Damage</li>
              <li>18 Lethality</li>
              <li>6% Movement Speed</li>
              <li>
                Passive – <strong>Murder</strong>: After being out of combat with Champions for 8 seconds gains bonus Lethality. This Lethality lasts for 3 seconds after dealing damage to champions.
              </li>
              <li>
                Passive – <strong>Murderspeed</strong>: If a champion dies within 3 seconds of damaging them, gain 150 decaying movement speed for 1.5 seconds.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_Efyuo6E7d/1x.webp" alt="rectrix" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Rectrix</strong>
            </p>
            <ul>
              <li>Total Cost: 900 gold</li>
              <li>Item Recipe: Long Sword + 550 gold</li>
              <li>20 Attack Damage</li>
              <li>4% Movement Speed</li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_AhhI2MWtw/1x.webp" alt="brutalizer" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>The Brutalizer</strong>
            </p>
            <ul>
              <li>Total Cost: 1337 gold</li>
              <li>Item Recipe: Glowing Mote + Pickaxe + 212 gold</li>
              <li>25 Attack Damage</li>
              <li>10 Ability Haste</li>
              <li>8 Lethality</li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/seryldas-grudge/1x.webp"
              alt="Serylda's Grudge"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Serylda’s Grudge</strong>
            </p>
            <ul>
              <li>Total Cost: 3200 gold</li>
              <li>Item Recipe: Brutalizer + Last Whisper + 413 gold</li>
              <li>45 Attack Damage</li>
              <li>15 Lethality</li>
              <li>15 Ability Haste</li>
              <li>
                Passive – <strong>Rancor</strong>: Gain 22 (+12%) Lethality Armor Penetration
              </li>
              <li>
                Passive – <strong>Bitter Cold</strong>: Damaging abilities slow enemies below 50% Health by 30% for 1 second
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/axiom-arc/1x.webp"
              alt="Axiom Arc"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Axiom Arc</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Brutalizer + Caulfield’s Warhammer + 563 gold</li>
              <li>55 Attack Damage</li>
              <li>18 Lethality</li>
              <li>25 Ability Haste</li>
              <li>
                Passive - <strong>Flux</strong>: Whenever a Champion dies within 3 seconds of you having damaged them, refund 10 = (10 + 35% Lethality)% of your Ultimate Ability's total cooldown.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/youmuus-ghostblade/1x.webp"
              alt="Youmuu's Ghostblade"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Youmuu's Ghostblade</strong>
            </p>
            <ul>
              <li>Total Cost: 2700 gold</li>
              <li>Item Recipe: Serrated Dirk + Rectrix + 800 gold</li>
              <li>60 Attack Damage</li>
              <li>18 Lethality</li>
              <li>
                Passive - <strong>Haunt</strong>: Gain 40 Move Speed while out of combat.
              </li>
              <li>
                Active -<strong> Wraith Step</strong>: Gain 20% Move Speed and <strong>Ghosting </strong>for 6 seconds. 45-second cooldown.
              </li>
              <li>
                <strong>Ghosting</strong>: Ignore collision with other units.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/umbral-glaive/1x.webp"
              alt="Umbral Glaive"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Umbral Glave</strong>
            </p>
            <ul>
              <li>Total Cost: 2600 gold</li>
              <li>Item Recipe: Long Sword + Serrated Dirk + Long Sword + 800 gold</li>
              <li>50 Attack Damage</li>
              <li>15 Lethality</li>
              <li>15 Ability Haste</li>
              <li>
                Passive - <strong>Blackout:</strong> When spotted by an enemy Ward, reveal traps and disable Wards around you for 8 seconds. Your attacks do 3x damage to Wards.&nbsp;
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/edge-of-night/1x.webp"
              alt="Edge of Night"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Edge of Night</strong>
            </p>
            <ul>
              <li>Total Cost: 2800 gold</li>
              <li>Item Recipe: Serrated Dirk + Tunneler + 700 gold</li>
              <li>50 Attack Damage</li>
              <li>15 Lethality</li>
              <li>250 Health</li>
              <li>
                Passive - <strong>Annul:</strong> Gain a Spell Shield that blocks the next enemy Ability. 40-second cooldown. Item's cooldown is restarted when damage is taken from champions.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/serpents-fang/1x.webp"
              alt="Serpent's Fang"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Sepent's Fang</strong>
            </p>
            <ul>
              <li>Total Cost: 2500 gold</li>
              <li>Item Recipe: Serrated Dirk + Pickaxe+ 700 gold</li>
              <li>55 Attack Damage</li>
              <li>15 Lethality</li>
              <li>
                Passive - <strong>Shield Reaver:</strong> Dealing damage to an enemy champion reduces any shields they gain by (50 for melee|35 for ranged)% for 3 seconds. When you damage an enemy who
                is unaffected by <strong>Shield Reaver</strong>, reduce all shields on them by (50 for melee|35 for ranged)%. Does not reduce the size of shields which only block magic damage. Item
                performance differs for melee and ranged users.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/eclipse/1x.webp"
              alt="Eclipse"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Eclipse</strong>
            </p>
            <ul>
              <li>Total Cost: 2800 gold</li>
              <li>Item Recipe: Caulfield’s Warhammer + Pickaxe + Long Sword + 475 gold</li>
              <li>70 Attack Damage</li>
              <li>15 Ability Haste</li>
              <li>
                Passive - <strong>Ever Rising Moon: </strong>Hitting a champion with 3 separate Attacks or Abilities within 2 seconds deals (6 melee|3 ranged)% bonus physical damage and grants you 122
                = (120 + 35% bonus AD) Shield for 2 seconds. 6-second cooldown. Item performance differs for melee and ranged users.
              </li>
            </ul>
          </div>
        </div>

        <h3>
          <a id="mage-items" href="#mage-items">
            Mage Items and Changes Season 14
          </a>
        </h3>
        <p>
          Removed Items and Changes: <strong>Crown of the Shattered Queen, Everfrost, Leeching Leer, Demonic Embrace, Night Harvester, Luden’s Tempest, Stopwatch (and the Perfect Timing rune)</strong>
        </p>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_0pMSpSK1i/1x.webp" alt="malignance" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Malignance</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Lost Chapter + Fiendish Codex + 900 gold</li>
              <li>80 Ability Power</li>
              <li>20 Ability Haste</li>
              <li>600 Mana</li>
              <li>
                <strong>Ultimate Power</strong>: Gain 15 Ability Haste for your Ultimate.
              </li>
              <li>
                <strong>Ultimate Flames</strong>: Whenever you damage an enemy champion with your Ultimate, burn the ground beneath them for 3 seconds, dealing 60 (+6% AP) magic damage every second
                and reducing their Magic Resistance by (6-12 [level scaling]) for as long as they are on the burning ground.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_jKLTLRaBx/1x.webp" alt="caster's" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Caster’s Companion</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Lost Chapter + Hextech Alternator + 700 gold</li>
              <li>90 Ability Power</li>
              <li>20 Ability Haste</li>
              <li>600 Mana</li>
              <li>
                <strong>Load</strong>: Gain a Shot Charge every 3 seconds, up to a maximum of 6.
              </li>
              <li>
                <strong>Fire</strong>: Damaging abilities consume all Shot charges to deal an additional 40 (+ 8% AP) magic damage to the target and one additional nearby target per charge. If there
                are insufficient targets in range, for each remaining Shot, repeat the damage on the primary target dealing 35% of the damage.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_OegO08FOB/1x.webp" alt="stormsurge" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Stormsurge</strong>
            </p>
            <ul>
              <li>Total Cost: 2900 gold</li>
              <li>Item Recipe: Hextech Alternator + Aether Wisp + 950 gold</li>
              <li>90 Ability Power</li>
              <li>10 Magic Penetration</li>
              <li>5% Movement Speed</li>
              <li>
                Passive&nbsp;<strong>Stormraider</strong>: Dealing 35% of a champion’s maximum health within 3 seconds applies Stormsurge to them and grants the user 25% movement speed for 2 seconds.
                20 second cooldown.
              </li>
              <li>
                Passive – <strong>Stormsurge</strong>: After 2 seconds, Stormsurge strikes the target with lightning, dealing 100-200 (based on level) (+ 50% AP) magic damage to them. If they die to
                the lightning or before the lightning strikes, it detonates immediately in a large area around them and you gain 30 gold.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_3Ij9Xqe38/1x.webp" alt="Haunting" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Haunting Guise</strong>
            </p>
            <ul>
              <li>Total Cost: 1300 gold</li>
              <li>Item Recipe: Amplifying Tome + Ruby Crystal + 500 gold</li>
              <li>35 Ability Power</li>
              <li>200 Health</li>
              <li>
                Passive – <strong>Madness</strong>: For each second in combat with enemy champions, deal 2% bonus damage (maximum of 6%).
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img__C119farU/1x.webp" alt="CRYPT.png" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Cryptbloom</strong>
            </p>
            <ul>
              <li>Total Cost: 2850 gold</li>
              <li>Item Recipe: Blighting Jewel + Fiendish Codex + 850 gold</li>
              <li>70 Ability Power</li>
              <li>15 Ability Haste</li>
              <li>30% Magic Penetration</li>
              <li>
                Passive – <strong>Life From Death</strong>: Whenever you get a takedown on an enemy champion within 3 seconds of damaging them, create a healing nova on their location that heals
                allies for 50 (+50% AP). 60-second cooldown.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/riftmaker/1x.webp"
              alt="Riftmaker"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Riftmaker</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Haunting Guise + Fiendish Codex + 800 gold</li>
              <li>70 Ability Power</li>
              <li>15 Ability Haste</li>
              <li>300 Health</li>
              <li>
                <strong>Void Corruption</strong>: For each second in combat with enemy champions, deal 2% bonus damage (max 10%). At maximum strength, gain 10% (melee) / 6% (ranged) Omnivamp.
              </li>
              <li>
                <strong>Void Infusion</strong>: Gain 2% of your bonus health as Ability Power.
              </li>
              <li>
                Omnivamp updated with the following (now only exists on Riftmaker):
                <ul>
                  <li>Omnivamp heals you for a percentage of damage you deal, with reduced effect (33% effectiveness) on minions and monsters.</li>
                  <li>Omnivamp heals you for the full amount with Pet or AOE damaged</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/liandrys-anguish/1x.webp"
              alt="Liandry's Anguish"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Liandry’s Torment</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Haunting Guise + Blasting Wand + 850 gold</li>
              <li>90 Ability Power</li>
              <li>300 Health</li>
              <li>
                Passive – <strong>Torment</strong>: Dealing damage with abilities causes enemies to burn for 2% maximum health magic damage per second for 3 seconds.
              </li>
              <li>
                Passive – <strong>Suffering</strong>: For each second in combat with enemy champions, gain 2% bonus damage (max at 6% bonus damage).
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/horizon-focus/1x.webp"
              alt="Horizon Focus"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Horizon Focus</strong>
            </p>
            <ul>
              <li>Total Cost: 2700 gold</li>
              <li>Item Recipe: Hextech Alternator + Fiendish Codex + 700 gold</li>
              <li>90 Ability Power</li>
              <li>20 Ability Haste</li>
              <li>
                Passive – <strong>Hypershot</strong>: When you deal damage with Abilities to champions at 700 range or greater, reveal them for 6 seconds. Deal 10% increased damage to enemies revealed
                by Hypershot.
              </li>
              <li>
                Passive – <strong>Focus</strong>: When revealing an enemy with Hypershot, reveal all other enemy champions within 1200 range of them for 2 seconds. 30 second cooldown.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/seekers-armguard/1x.webp"
              alt="Seeker's Armguard"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Seeker’s Armguard</strong>
            </p>
            <ul>
              <li>Total Cost: 1600 gold</li>
              <li>Item Recipe: Amplifying Tome + Amplifying Tome + Cloth Armor + 500 gold</li>
              <li>40 Ability Power</li>
              <li>35 Armor</li>
              <li>
                Active – <strong>Stasis</strong>: Use one time only to become Invulnerable and Untargetable for 2.5 seconds. The user is prevented from taking any other actions during this time
                (transforms into Broken Armguard on use).
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/shadowflame/1x.webp"
              alt="Shadowflame"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Shadowflame</strong>
            </p>
            <ul>
              <li>Total Cost: 3200 gold</li>
              <li>Item Recipe: Needlessly Large Rod + Hextech Alternator + 850 gold</li>
              <li>120 Ability Power</li>
              <li>12 Magic Penetration</li>
              <li>
                Passive – <strong>Cinderbloom</strong>: Magic damage and true damage critically strikes enemies below 35% health, dealing 20% increased damage (reduced to 30% increased damage for
                damage over time and pet effects).
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/cosmic-drive/1x.webp"
              alt="Cosmic Drive"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Cosmic Drive</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Kindlegem + Aether Wisp + Fiendish Codex + 450 gold</li>
              <li>80 Ability Power</li>
              <li>250 Health</li>
              <li>25 Ability Haste</li>
              <li>5% Movement Speed</li>
              <li>
                Passive – <strong>Spelldance</strong>: Damaging an enemy champion with an ability grants bonus movement speed for 2 seconds.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/amplifying-tome/1x.webp"
              alt="Amplifying Tome"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Amplifying Tome</strong>
            </p>
            <ul>
              <li>Cost: 400 gold (can you believe we actually did it!?)</li>
              <li>20 Ability Power</li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/rod-of-ages/1x.webp"
              alt="Rod of Ages"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Rod of Ages</strong>
            </p>
            <ul>
              <li>Total Cost: 2700 gold</li>
              <li>Item Recipe: Blasting Wand + Catalyst + 550 gold</li>
              <li>50 Ability Power</li>
              <li>300 Health</li>
              <li>300 Mana</li>
              <li>
                This item gains 20 Health, 20 Mana, and 4 Ability Power every 60 seconds up to 10 times, for a maximum of 200 Health, 200 Mana, and 40 Ability Power. Upon reaching maximum stacks, gain
                a level.
              </li>
              <li>
                Passive – <strong>Eternity</strong>: Restore Mana equal to 7% of premitigation damage taken from champions, and Health equal to 25% of Mana spent, up to 20 Health per cast, per second
              </li>
              <li>
                <strong>Removed</strong>: For every 200 healing or mana restored with Eternity, gain 35% bonus movement speed that decays over 3 seconds.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/zhonyas-hourglass/1x.webp"
              alt="Zhonya's Hourglass"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Zhonya's Hourglass</strong>
            </p>
            <ul>
              <li>Total Cost: 3250 gold</li>
              <li>Item Recipe: Needlessly Large Rod + Seeker’s Armguard + 400 gold</li>
              <li>120 Ability Power</li>
              <li>50 Armor</li>
              <li>
                Active - <strong>Time Stop</strong>: Enter <strong>Stasis </strong>for 2.50 seconds. 120-second cooldown.&nbsp;<strong>Stasis</strong>: Become Invulnerable and Untargetable. Cannot
                move for the duration.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/seekers-armguard/1x.webp"
              alt="Seeker's Armguard"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Seeker's Armguard</strong>
            </p>
            <ul>
              <li>Total Cost: 1600 gold</li>
              <li>Item Recipe: Amplifying Tome + Cloth Armor + 900 gold</li>
              <li>45 Ability Power</li>
              <li>25 Armor</li>
              <li>
                Active - <strong>Time Stop</strong>: Enter <strong>Stasis </strong>for 2.50 seconds. Single use. <strong>Stasis</strong>: Become Invulnerable and Untargetable. Cannot move for the
                duration.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/rabadons-deathcap/1x.webp"
              alt="Rabadon's Deathcap"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Rabadon's Deathcap</strong>
            </p>
            <ul>
              <li>Total Cost: 3600 gold</li>
              <li>Item Recipe: Needlessly Large Rod + Needlessly Large Rod + 1100 gold</li>
              <li>140 Ability Power</li>
              <li>
                Passive - <strong>Magical Opus:&nbsp;</strong>Increases your total Ability Power by 35%
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/shadowflame/1x.webp"
              alt="Shadowflame"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Shadowflame</strong>
            </p>
            <ul>
              <li>Total Cost: 3200 gold</li>
              <li>Item Recipe: Hextech Alternator + Needlessly Large Rod + 850 gold</li>
              <li>120 Ability Power</li>
              <li>12 Magic Penetration</li>
              <li>
                Passive - <strong>Cinderbloom</strong>: Magic damage and true damage critically strikes enemies below 35% health, dealing 20% increased damage (30% increased damage for damage over
                time and pet effects). Critical damage modifiers only affect <strong>Cinderbloom's</strong> bonus damage.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/lich-bane/1x.webp"
              alt="Lich Bane"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Lich Bane</strong>
            </p>
            <ul>
              <li>Total Cost: 3100 gold</li>
              <li>Item Recipe: Sheen + Aether Wisp + Hextech Alternator + 150 gold</li>
              <li>100 Ability Power</li>
              <li>8% Move Speed</li>
              <li>15 Ability Haste</li>
              <li>
                Passive - <strong>Spellblade</strong>: After using an Ability, your next Attack is enhanced with an additional 57 = (100% base AD + 50% AP) magic damage on-hit. Spellblade grants 30%
                Attack Speed while available. 1.5 seconds cooldown.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/verdant-barrier/1x.webp"
              alt="Verdant Barrier"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Verdant Barrier</strong>
            </p>
            <ul>
              <li>Total Cost: 1800 gold</li>
              <li>Item Recipe: Null-Magic Mantle + Amplifying Tome + Amplifying Tome + 550 gold</li>
              <li>40 Ability Power</li>
              <li>30 Magic Resist</li>
              <li>
                Passive - <strong>Annul</strong>: Grants a Spell Shield that blocks the next enemy Ability. 60-second cooldown.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/banshees-veil/1x.webp"
              alt="Banshee's Veil"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Banshee's Veil</strong>
            </p>
            <ul>
              <li>Total Cost: 3100 gold</li>
              <li>Item Recipe: Needlessly Large Rod + Verdant Barrier + 50 gold.</li>
              <li>120 Ability Power</li>
              <li>50 Magic Resist</li>
              <li>
                Passive - <strong>Annul</strong>: Grants a Spell Shield that blocks the next enemy Ability. 30-second cooldown. Item cooldown is restarted when damage is taken from champions.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/void-staff/1x.webp"
              alt="Void Staff"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Void Staff</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Blighting Jewel + Blasting Wand + 1050 gold.</li>
              <li>65 Ability Power</li>
              <li>40% Magic Penetration</li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/hextech-rocketbelt/1x.webp"
              alt="Hextech Rocketbelt"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Hextech Rocketbelt</strong>
            </p>
            <ul>
              <li>Total Cost: 2500 gold</li>
              <li>Item Recipe: Hextech Alternator + Kindlegem + 600 gold.</li>
              <li>60 Ability Power</li>
              <li>300 Health</li>
              <li>15 Ability Haste</li>
              <li>
                Active - <strong>Supersonic</strong>: Dash in target direction, unleashing an arc of magic missiles that deal 175 = (175 + 15% AP) magic damage. 40-second cooldown.{" "}
                <strong>Supersonic's </strong>dash cannot pass through terrain.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/morellonomicon/1x.webp"
              alt="Morellonomicon"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Morellonomicon</strong>
            </p>
            <ul>
              <li>Total Cost: 2200 gold</li>
              <li>Item Recipe: Oblivion Orb + Fiendish Codex + 500 gold.</li>
              <li>90 Ability Power</li>
              <li>15 Ability Haste</li>
              <li>
                Passive - <strong>Grievous Wounds</strong>: Dealing magic damage applies 40% <strong>Grievous Wounds</strong> to enemy champions for 3 seconds.&nbsp;
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/nashors-tooth/1x.webp"
              alt="Nashor's Tooth"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Nashor's Tooth</strong>
            </p>
            <ul>
              <li>Total Cost: 3000 gold</li>
              <li>Item Recipe: Recurve Bow + Blasting Wand + Fiendish Codex + 550 gold.</li>
              <li>80 Ability Power</li>
              <li>50% Attack SPeed</li>
              <li>15 Ability Haste</li>
              <li>
                Passive - <strong>Icathian Bite</strong>: Attacks apply 15 (AP) magic damage on-hit.
              </li>
            </ul>
          </div>
        </div>

        <h3>
          <a id="support-items" href="#support-items">
            Support Items and Changes Season 14
          </a>
        </h3>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_QF2itegT6/1x.webp" alt="atlas" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>World Atlas</strong>
            </p>
            <ul>
              <li>Item Cost: 40 gold</li>
              <li>30 Health</li>
              <li>25% Mana Regeneration</li>
              <li>25% Health Regeneration</li>
              <li>3 gold per 10 seconds</li>
              <li>
                <strong>Support Quest:</strong> Earn 500 gold from this item to transform it into Runic Compass and gain the ability to hold wards.
              </li>
              <li>
                <strong>Gold Generation</strong>: Gain a charge every 18 seconds, up to 3 charges. While nearby an ally champion, consume a charge to earn gold via the following methods:
                <ul>
                  <li>Damaging abilities and attacks against champions or structures grant 30 (melee) / 28 (ranged) gold.</li>
                  <li>Killing a minion by any means grants you 20 gold and the nearest allied champion the same amount of gold they would have received had they killed the minion.</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_eW1jyLMrO/1x.webp" alt="compass" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Runic Compass</strong>
            </p>
            <ul>
              <li>Item Cost: Upgraded from World Atlas at 500 gold.</li>
              <li>100 Health</li>
              <li>50% Mana Regeneration</li>
              <li>50% Health Regeneration</li>
              <li>5 gold per 10 seconds</li>
              <li>
                <strong>Support Quest</strong>: Earn 1000 gold from this item to transform it into Runic Compass and gain the ability to hold wards.
              </li>
              <li>
                <strong>Gold Generation</strong>: Gain a charge every 18 seconds, up to 3 charges. While nearby an ally champion, consume a charge to earn gold via the following methods:
                <ul>
                  <li>Damaging abilities and attacks against champions or structures grant 34 (melee) / 32 (ranged) gold.</li>
                  <li>Killing a minion by any means grants you 28 gold and the nearest allied champion the same amount of gold they would have received had they killed the minion.</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_SEDhqLzyQ/1x.webp" alt="bounty.png" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Bounty of Worlds</strong>
            </p>
            <ul>
              <li>
                You may notice Bounty of Worlds is almost exactly the same item as Runic Compass. Well, you noticed right. Bounty of Worlds is an in-between state that allows the item to upgrade into
                any of the five support item upgrades! Once upgraded it served its purpose. Thank you, Bounty of Worlds.
              </li>
              <li>Item Cost: Upgraded from Runic Compass at 1000 gold</li>
              <li>100 Health</li>
              <li>50% Mana Regeneration</li>
              <li>50% Health Regeneration</li>
              <li>5 gold per 10 seconds</li>
            </ul>
          </div>
        </div>
        <h3>
          <strong>All Upgraded Items</strong>
        </h3>
        <p>It looks like all upgraded items will share the same stats with the differentiator being the upgrades mentioned below.</p>
        <ul style={{ marginBottom: "15px" }}>
          <li>200 Health</li>
          <li>75% Mana Regeneration</li>
          <li>75% Health Regeneration</li>
          <li>5 gold per 10 seconds</li>
        </ul>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_il7MJltO4/1x.webp" alt="celestial" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Celestial Opposition</strong>
            </p>
            <ul>
              <li>
                Passive – <strong>Blessing of the Mountain:</strong> Become Blessed to reduce incoming champion damage by 40% (melee) / 25% (ranged), lingering for 2 seconds after taking damage from a
                champion. When the reduction wears off, unleash a shockwave around you that slows nearby enemies by 50% for 1.5 seconds. Effect refreshes after leaving combat for 15 seconds.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_0VCiIvlCJ/1x.webp" alt="sleigh" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Solstice Sleigh</strong>
            </p>
            <ul>
              <li>
                <strong>Passive</strong>: Slowing or Immobilizing an enemy champion grants you and a nearby ally with the lowest amount of health 120 bonus health and 90 movement speed for 4 seconds.
                20-second cooldown.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_4GYHSj8iV/1x.webp" alt="bloodsong" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Bloodsong</strong>
            </p>
            <ul>
              <li>
                Passive – <strong>Spellblade</strong>: After using an ability, your next attack is enhanced with an additional 75 physical damage on-hit (1.5-second cooldown). If the target is a
                champion, apply Expose Weakness, increasing the damage they take by 12% (melee) / 8% (ranged) for 6 seconds.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_S5l5XhWZO/1x.webp" alt="dream" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Dream Maker</strong>
            </p>
            <ul>
              <li>
                Passive –<strong> Dream Maker</strong>: Gain a Blue Dream Bubble and a Purple Dream Bubble every 8 seconds. Healing and Shielding another ally blows both Dream Bubbles to them and
                empowers them for 3 seconds. Blue Bubble reduces 140 incoming damage on the next hit and Purple Bubble grants 90 bonus magic damage on the next hit.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img src="https://cdn.gameleap.com/images/articles/art_niAuVBnYUw/art-img_abD98pR9z/1x.webp" alt="realmspike" />
            <span className="badge">New</span>
          </div>
          <div>
            <p>
              <strong>Zaz’Zak’s Realmspike</strong>
            </p>
            <ul>
              <li>
                Passive – <strong>Void Explosion</strong>: Dealing ability damage to a champion causes an explosion at their current location that damages the target and nearby enemies, dealing 50
                (+3% maximum health) magic damage, capped at 300 against monsters. 3 second cooldown.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <div className="misc-container">
            <div className="img-wrapper">
              <img
                src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/mikaels-blessing/1x.webp"
                alt="Mikael's Blessing"
                width="64px"
                height="64px"
                className="category-league-of-legends subcategory-items"
              />
            </div>
            <div>
              <p>
                <strong>Mikael's Blessing</strong>
              </p>
              <ul>
                <li>Total Cost: 2300 gold</li>
                <li>Item Recipe: Chalice of Blessing + Forbidden Idol + 550 gold</li>
                <li>250 Health</li>
                <li>100% Base Mana Regen</li>
                <li>12% Heal and Shield Power</li>
                <li>15 Ability Haste</li>
                <li>
                  Active - <strong>Purify</strong>: Remove all crowd control debuffs (except <strong>Knockups </strong>and <strong>Suppression</strong>) from an ally champion and restore 100-250 (ally
                  target level) Health. 120-second cooldown.
                </li>
              </ul>
            </div>
          </div>
          <div className="misc-container">
            <div className="img-wrapper">
              <img
                src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/redemption/1x.webp"
                alt="Redemption"
                width="64px"
                height="64px"
                className="category-league-of-legends subcategory-items"
              />
            </div>
            <div>
              <p>
                <strong>Redemption</strong>
              </p>
              <ul>
                <li>Total Cost: 2300 gold</li>
                <li>Item Recipe: Kindlegem + Forbidden Idol + 700 gold</li>
                <li>200 Health</li>
                <li>100% Base Mana Regen</li>
                <li>15% Heal and Shield Power</li>
                <li>
                  Active - <strong>Intervention</strong>: Target an area. After 2.5 seconds, restore 200-400 (ally target level) Health to allied champions and deal 10% max Health true damage to enemy
                  champions. 90-second cooldown.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/shurelyas-battlesong/1x.webp"
              alt="Shurelya's Battlesong"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Shurelya's Battlesong</strong>
            </p>
            <ul>
              <li>Total Cost: 2200 gold</li>
              <li>Item Recipe: Aether Wisp + Bandleglass Mirror + 350 gold</li>
              <li>55 Ability Power</li>
              <li>15 Ability Haste</li>
              <li>8% Move Speed</li>
              <li>125% Base Mana Regen</li>
              <li>
                Active - <strong>Inspiring Speech</strong>: Grants nearby allies 30% Move Speed for 4 seconds. 75-second cooldown.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/locket-of-the-iron-solari/1x.webp"
              alt="Locket of the Iron Solari"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Locket of the Iron Solari</strong>
            </p>
            <ul>
              <li>Total Cost: 2200 gold</li>
              <li>Item Recipe: Kindlegem + Cloth Armor + Null-Magic Mantle + 650 gold</li>
              <li>200 Health</li>
              <li>30 Armor</li>
              <li>30 Magic Resist</li>
              <li>10 Ability Haste</li>
              <li>
                Active - <strong>Devotion</strong>: Grants nearby allies a 200-360 (ally level) Shield, decaying over 2.5 seconds. Strength of level-scaling effects is based on the ally's level.
                Subsequent Devotion shields within 20 seconds only have 25% effect. 90-second cooldown.
              </li>
            </ul>
          </div>
        </div>

        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/knights-vow/1x.webp"
              alt="Knight's Vow"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Knight's Vow</strong>
            </p>
            <ul>
              <li>Total Cost: 2200 gold</li>
              <li>Item Recipe: Kindlegem + Chain Vest + 600 gold</li>
              <li>300 Health</li>
              <li>45 Armor</li>
              <li>15 Ability Haste</li>
              <li>
                Passive - <strong>Sacrifice</strong>: While your Worthy ally is nearby, redirect 10% of premitigation damage they take to you and heal for 10% of the damage dealt by your Worthy ally
                to Champions. If they have less than 30% Healt the damage reduction is increased to 20%. Champions can only be linked by one Knight's Vow at at time. Damage redirection stops if you
                have less than 30% Health.
              </li>
              <li>
                Active - <strong>Pledge</strong>: Designate an ally who is worthy of being your Knight. 60-second&nbsp;cooldown.
              </li>
            </ul>
          </div>
        </div>
        <div className="misc-container">
          <div className="img-wrapper">
            <img
              src="https://cdn.gameleap.com/images/article_assets/league-of-legends/items/zekes-convergence/1x.webp"
              alt="Zeke's Convergence"
              width="64px"
              height="64px"
              className="category-league-of-legends subcategory-items"
            />
          </div>
          <div>
            <p>
              <strong>Zeke's Convergence</strong>
            </p>
            <ul>
              <li>Total Cost: 2200 gold</li>
              <li>Item Recipe: Kindlegem + Glacial Buckler + 500 gold</li>
              <li>200 Health</li>
              <li>250 Mana</li>
              <li>30 Armor</li>
              <li>20 Ability Haste</li>
              <li>
                Active - <strong>Frostfire Tempest</strong>: Cating your Ultimate summons a storm around you for 5 seconds, dealing 50 magic damage per second and slows enemies by 30%. 45-second
                cooldown.
              </li>
            </ul>
          </div>
        </div>
      </Section>
      <ScrollTop />
    </>
  );
};
