import styled from "styled-components/macro";

import alien from "../assets/img/hobbies/alien.jpg";
import anime from "../assets/img/hobbies/anime.jpg";
import bodybuilding from "../assets/img/hobbies/bodybuilding.jpg";
import coffee from "../assets/img/hobbies/coffee.jpg";
import eletronics from "../assets/img/hobbies/eletronics.jpg";
import woodwork from "../assets/img/hobbies/woorwork.jpg";
import games from "../assets/img/hobbies/games.jpg";
import iot from "../assets/img/hobbies/iot.jpg";
import japan from "../assets/img/hobbies/japan.jpg";
import monster from "../assets/img/hobbies/monster.webp";
import physics from "../assets/img/hobbies/physics.jpg";
import streaming from "../assets/img/hobbies/streaming.png";
import { media } from "../components/Media";

const Element = styled.div`
  width: 100%;
  max-width: calc(var(--sectionSize) - 150px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  ${media.tablet`
    grid-template-columns: repeat(4, 1fr);
  `}
  gap: 5px;
  margin: 0 auto;
  text-align: center;
  cursor: default;
  user-select: none;
`;

const Hobby = styled.div<{ backgroundImage: string; backgroundSize: string }>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: ${(props) => props.backgroundSize || "280%"};
  width: 100%;
  display: grid;
  grid-column: unset;
  padding: 8px 0;
  box-sizing: border-box;
  background-color: crimson;
  box-shadow: 2px 4px 12px black;
  align-items: center;
  background-position: center center;
  min-height: 140px;
  font-size: 18px;
  &:hover {
    filter: hue-rotate(172deg);
    font-size: 19px;
    span {
      margin: 0 3px;
    }
  }
`;

const Label = styled.span`
  filter: drop-shadow(2px 3px 0px black);
  margin: 0 5px;
  backdrop-filter: brightness(0.6);
`;

export const Hobbies = () => {
  const items = [
    {
      label: "Games",
      backgroundImage: games,
      backgroundSize: "220%",
      title: "LoL, Ragnarok, Super Mario ♥"
    },
    {
      label: "Anime",
      backgroundImage: anime,
      title: "Evangelion, Sword Art, ReZero ♥"
    },
    {
      label: "Streaming",
      backgroundImage: streaming,
      title: "Follow me at twitch.tv/xjeeh_",
      backgroundSize: "150%"
    },
    {
      label: "Bodybuilding",
      backgroundImage: bodybuilding,
      backgroundSize: "170%",
      title: "Exercising body and mind"
    },
    {
      label: "Woodwork",
      backgroundImage: woodwork,
      title: "Carving and creating things"
    },
    {
      label: "IoT \nAutomation",
      backgroundImage: iot,
      backgroundSize: "500%",
      title: "Arduino ♥ Alexa ♥ Raspberry"
    },
    {
      label: "Physics",
      backgroundImage: physics,
      title: "Electromagnetism ♥"
    },
    {
      label: "Electronics",
      backgroundImage: eletronics,
      title: "Electronics + IoT = ♥"
    },
    {
      label: "Space/Aliens",
      backgroundImage: alien,
      backgroundSize: "190%",
      title: "Yeah they exist and I can prove it"
    },
    {
      label: "Coffee",
      backgroundImage: coffee,
      backgroundSize: "150%",
      title: "Strong and Black Coffee ♥"
    },
    {
      label: "Energy \nDrinks",
      backgroundImage: monster,
      backgroundSize: "100%",
      title: "Monster ♥"
    },
    {
      label: "Japan",
      backgroundImage: japan,
      backgroundSize: "300%",
      title: "One day I'll visit there :("
    }
  ];

  return (
    <Element>
      {items.map((i) => (
        <Hobby key={i.label} title={i.title} backgroundImage={i.backgroundImage} backgroundSize={i.backgroundSize || "280%"}>
          <Label>{i.label}</Label>
        </Hobby>
      ))}
    </Element>
  );
};
