import styled from "styled-components/macro";
import { media } from "./Media";
import languageColors from "github-language-colors";
import axios from "axios";
import { useState, useEffect } from "react";

const Element = styled.div`
  width: 100%;
  max-width: var(--sectionSize);
  grid-template-columns: repeat(1, 1fr);
  ${media.desktop`
    grid-template-columns: repeat(2, 1fr);
  `}
  display: grid;
  gap: 5px;
`;

const Project = styled.a`
  display: grid;
  color: white;
  cursor: pointer;
  background: var(--darkThree);
  padding: 8px 10px;
  box-sizing: border-box;
  position: relative;
`;

const Fork = styled.span`
  position: absolute;
  top: 5px;
  right: -6px;
  transform: rotate(45deg);
  font-size: 12px;
  color: white;
  background: #8d1f1f;
  padding: 0 3px;
`;

const Name = styled.div`
  color: var(--primary);
  text-decoration: underline;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 3px;
`;

const Description = styled.span`
  margin: 15px 0px;
  flex-grow: 1;
  font-size: 14px;
  font-family: "Arial";
`;

const Language = styled.div`
  display: flex;
  align-items: center;
`;

const TechnologyColor = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  background: ${(props) => props.color};
  border-radius: 100px;
  margin-right: 5px;
  box-shadow: 0px 0px 3px 0px;
`;

const TechnologyLabel = styled.span`
  font-size: 14px;
`;

interface IRepository {
  id: number;
  node_id: string;
  name: string;
  full_name: string;
  private: boolean;
  owner: {
    login: string;
    id: number;
    node_id: string;
    avatar_url: string;
    gravatar_id: string;
    url: string;
    html_url: string;
    followers_url: string;
    following_url: string;
    gists_url: string;
    starred_url: string;
    subscriptions_url: string;
    organizations_url: string;
    repos_url: string;
    events_url: string;
    received_events_url: string;
    type: string;
    site_admin: boolean;
  };
  html_url: string;
  description: string;
  fork: true;
  url: string;
  forks_url: string;
  keys_url: string;
  collaborators_url: string;
  teams_url: string;
  hooks_url: string;
  issue_events_url: string;
  events_url: string;
  assignees_url: string;
  branches_url: string;
  tags_url: string;
  blobs_url: string;
  git_tags_url: string;
  git_refs_url: string;
  trees_url: string;
  statuses_url: string;
  languages_url: string;
  stargazers_url: string;
  contributors_url: string;
  subscribers_url: string;
  subscription_url: string;
  commits_url: string;
  git_commits_url: string;
  comments_url: string;
  issue_comment_url: string;
  contents_url: string;
  compare_url: string;
  merges_url: string;
  archive_url: string;
  downloads_url: string;
  issues_url: string;
  pulls_url: string;
  milestones_url: string;
  notifications_url: string;
  labels_url: string;
  releases_url: string;
  deployments_url: string;
  created_at: string;
  updated_at: string;
  pushed_at: string;
  git_url: string;
  ssh_url: string;
  clone_url: string;
  svn_url: string;
  homepage: string;
  size: number;
  stargazers_count: number;
  watchers_count: number;
  language: string;
  has_issues: boolean;
  has_projects: boolean;
  has_downloads: boolean;
  has_wiki: boolean;
  has_pages: boolean;
  has_discussions: boolean;
  forks_count: number;
  mirror_url: null;
  archived: boolean;
  disabled: boolean;
  open_issues_count: number;
  license: {
    key: string;
    name: string;
    spdx_id: string;
    url: string;
    node_id: string;
  };
  allow_forking: boolean;
  is_template: boolean;
  web_commit_signoff_required: boolean;
  topics: [];
  visibility: string;
  forks: number;
  open_issues: number;
  watchers: number;
  default_branch: string;
}

export const Projects = () => {
  const [repositories, setRepositories] = useState<IRepository[]>([]);

  useEffect(() => {
    const getLinkedinProfile = async () => {
      const { data } = await axios.get("https://api.github.com/users/xjeeh/repos");
      setRepositories(data);
    };
    getLinkedinProfile();
  }, []);

  return (
    <Element>
      {repositories.map((repo) => (
        <Project key={repo.id} target={"_blank"} href={repo.html_url} rel="noreferrer">
          {repo.fork && <Fork>Fork</Fork>}
          <Name>{repo.name}</Name>
          <Description>{repo.description}</Description>
          <Language>
            <TechnologyColor color={languageColors[repo.language as keyof typeof languageColors]} />
            <TechnologyLabel>{repo.language}</TechnologyLabel>
          </Language>
        </Project>
      ))}
    </Element>
  );
};
