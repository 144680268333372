import styled, { keyframes } from "styled-components/macro";
import { media } from "./Media";

export const Title = styled.a<{ fontSize?: string; margin?: string; primary?: boolean; color?: string }>`
  display: block;
  font-weight: bold;
  color: ${(props) => (props.primary ? "var(--primary)" : props.color || "white")};
  text-align: center;
  font-size: ${(props) => `calc(15px + ${props.fontSize || 1}vmin)`};
  ${media.desktop`
    font-size: ${(props: any) => `calc(10px + ${props.fontSize || 1}vmin)`};
  `};
  margin: ${(props) => props.margin || 0};
`;

const swinging = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale3d(1.0, 1, 1);
  }
  50% {
    transform: translate3d(0, 0, 0) scale3d(1.01, 1, 1);
  }
`;

export const GradientTitle = styled(Title)`
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(194deg, #a23bff, #2f68e9);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  animation: 3s ${swinging} ease infinite;
`;
