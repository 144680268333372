import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import pdfEnUs from "../assets/pdf/Jefferson William dos Santos-en.pdf";
import pdfPtBr from "../assets/pdf/Jefferson William dos Santos-pt.pdf";

const Element = styled.div`
  display: flex;
  cursor: pointer;
  text-transform: capitalize;
`;

const File = styled.a`
  color: white;
  background: var(--darkThree);
  padding: 15px 30px;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--primary);
  }
`;

const FileWrapper = styled.div`
  position: relative;
  display: block;
  float: left;
  width: 30px;
  height: 25px;
  font-size: 25px;

  svg:nth-child(2) path {
    fill: #fd3131;
    clip-path: polygon(30% 65%, 100% 60%, 100% 100%, 30% 100%);
  }
`;

const PDFIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  left: 0;
`;

const Label = styled.span`
  font-size: 15px;
  font-weight: bold;
`;

export const Resume = () => {
  return (
    <Element>
      <File href={pdfEnUs} target="_blank" style={{ marginRight: "10px" }}>
        <FileWrapper className="fileWrapper">
          <PDFIcon icon={faFilePdf} />
          <PDFIcon icon={faFilePdf} />
        </FileWrapper>
        <Label>en-us</Label>
      </File>
      <File href={pdfPtBr} target="_blank">
        <FileWrapper>
          <PDFIcon icon={faFilePdf} />
          <PDFIcon icon={faFilePdf} />
        </FileWrapper>
        <Label>pt-br</Label>
      </File>
    </Element>
  );
};
