import { keyframes } from "styled-components/macro";
import styled from "styled-components/macro";
import profileImg from "../assets/img/profile.jpg";
import trustyImg from "../assets/img/trusty.png";
import coolImg from "../assets/img/cool.png";
import sexyImg from "../assets/img/sexy.png";
import shineImg from "../assets/img/shine.png";
import { media } from "../components/Media";
import profileBg from "./../assets/img/profileBg.gif";
import "animate.css";

interface IProps {
  trusty: number;
  cool: number;
  sexy: number;
}

const Element = styled.div`
  position: relative;
  background: linear-gradient(0deg, #e664bb, #6d1cef);
  padding: 3px;
  margin: 0;
  border-radius: 15px;
  display: grid;
  background: url(${profileBg});
  width: 200px;
  margin: 0 auto 1em;
  ${media.desktop`
    float: right;
    width: initial;
    margin: 0;
  `};
`;

const ProfileImage = styled.img`
  width: 190px;
  border-radius: 14px;
  border: solid 5px #202020;
`;

const Attributes = styled.div`
  display: flex;
  width: 160px;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
`;

const Attribute = styled.div`
  margin-right: 8px;
  .label {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    color: white;
    font-size: 14px;
  }
`;

const Label = styled.label`
  font-size: 15px;
`;

const Blink = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Progressbar = styled.div`
  position: relative;
  img {
    width: 15px;
  }
`;

const Shine = styled.img<{ variant?: string }>`
  width: 17px;
  position: absolute;
  bottom: -1px;
  right: -5px;
  opacity: 0;
  animation: 2s 1s ${Blink} ease infinite;
  &.cool {
    animation-delay: 2.2s;
    left: 20px;
    bottom: -2px;
  }
  &.sexy {
    animation-delay: 1.8s;
    left: 0;
  }
`;

const Trusty = styled.img``;
const Cool = styled.img``;
const Sexy = styled.img``;

const Progress = styled.div<{ progress: number }>`
  position: absolute;
  right: 0;
  width: ${(props) => 100 - props.progress}%;
  height: 100%;
  z-index: 1;
  backdrop-filter: grayscale(1) saturate(0);
`;

export const Profile = ({ trusty, cool, sexy }: IProps) => {
  return (
    <Element>
      <ProfileImage src={profileImg} />
      <Attributes>
        <Attribute>
          <Label>trusty</Label>
          <Progressbar>
            <Progress progress={trusty} />
            <Trusty src={trustyImg} />
            <Trusty src={trustyImg} />
            <Trusty src={trustyImg} />
            <Shine src={shineImg} className="trusty" />
          </Progressbar>
        </Attribute>
        <Attribute>
          <Label>cool</Label>
          <Progressbar>
            <Progress progress={cool} />
            <Cool src={coolImg} />
            <Cool src={coolImg} />
            <Cool src={coolImg} />
            <Shine src={shineImg} className="cool" />
          </Progressbar>
        </Attribute>
        <Attribute>
          <Label>sexy</Label>
          <Progressbar>
            <Progress progress={sexy} />
            <Sexy src={sexyImg} />
            <Sexy src={sexyImg} />
            <Sexy src={sexyImg} />
            <Shine src={shineImg} className="sexy" />
          </Progressbar>
        </Attribute>
      </Attributes>
    </Element>
  );
};
