import "animate.css";
import { StyleSheetManager } from "styled-components/macro";
import styled from "styled-components/macro";

import { media } from "../components/Media";
import { Menu } from "../layouts/Menu";
import { Contact } from "../layouts/Contact";
import { Hobbies } from "../layouts/Hobbies";
import { Projects } from "../components/Projects";
import { Experiences } from "../layouts/Experience";
import { Resume } from "../layouts/Resume";
import { SKills } from "../layouts/Skills";
import { Certifications } from "../layouts/Certifications";
import { About } from "../layouts/About";
import { GradientTitle, Title } from "../components/Title";
import { ScrollTop } from "../components/ScrollTop";
import { Container } from "../components/Container";
import { Section } from "../components/Section";
import { useEffect, useRef } from "react";
import { UseScrollConfig } from "../hooks/useScrollConfig";

const AppContainer = styled.div``;

const ExperienceAndSkillsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: var(--sectionSize);
  flex-wrap: wrap;
  gap: 20px;
  ${media.desktop`
    flex-wrap: nowrap;
  `}
`;

const Home = () => {
  const menuItems = [
    { name: "About", scrollTo: "about" },
    { name: "Experience", scrollTo: "experience" },
    { name: "Skill", scrollTo: "skills" },
    { name: "Certifications", scrollTo: "certifications" },
    { name: "Projects", scrollTo: "projects" },
    { name: "Resumé", scrollTo: "resume" },
    { name: "Hobbies", scrollTo: "hobbies" },
    { name: "Contact", scrollTo: "contact" }
  ];

  const experiences = [
    { name: "Software Engineering", start: "01/01/2013" },
    { name: "Web Development", start: "03/01/2014" },
    { name: "Front-End Development", start: "03/01/2014" },
    { name: "Tech Leadership", start: "03/01/2019" }
  ];

  // const isDev = process.env.NODE_ENV === "development";

  useEffect(() => {
    const hash = window.location.hash;
    if (!hash) return;
    setTimeout(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView();
        }
      } else {
        window.scrollTo(0, 0);
      }
    }, 500);
  }, []);

  return (
    <StyleSheetManager disableVendorPrefixes={true} disableCSSOMInjection>
      <AppContainer>
        <ScrollTop />

        <Menu items={menuItems} />

        <Container>
          <Section id="about" marginTop="0px">
            <GradientTitle className="animate__animated animate__fadeIn" fontSize="5" margin="0 0 .1em" color="var(--secondary)">
              Jefferson Santos
            </GradientTitle>
            <Title href="/" fontSize="1.5">
              Sr. Software Engineer • Tech Lead
            </Title>

            <About />
          </Section>

          <ExperienceAndSkillsWrapper>
            <Section id="experience">
              <Title margin="1em 0 1em" primary>
                Experience
              </Title>
              <Experiences items={experiences} />
            </Section>

            <Section id="skills">
              <Title margin="1em 0 1em" primary>
                Skills
              </Title>
              <SKills />
            </Section>
          </ExperienceAndSkillsWrapper>

          <Section id="certifications">
            <Title margin="1em 0 1em" primary>
              Certifications
            </Title>
            <Certifications />
          </Section>

          <Section id="projects">
            <Title margin="1em 0 1em" primary>
              Projects
            </Title>
            <Projects />
          </Section>

          <Section id="resume">
            <Title margin="1em 0 1em" primary>
              Resumé
            </Title>
            <Resume />
          </Section>

          <Section id="hobbies">
            <Title margin="1em 0 1em" primary>
              Hobbies/Interests
            </Title>
            <Hobbies />
          </Section>

          <Section id="contact">
            <Title margin="1em 0 1em" primary>
              Contact
            </Title>
            <Contact />
          </Section>
        </Container>
      </AppContainer>
    </StyleSheetManager>
  );
};

export default Home;
