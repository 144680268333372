import { differenceInMonths } from "date-fns";
import styled from "styled-components/macro";
import loadingBarBg from "./../assets/img/loadingBarBg.gif";

interface IProps {
  items: {
    name: string;
    start: string;
  }[];
}

const Element = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;
`;

const Experience = styled.div`
  background: var(--darkThree);
  margin-bottom: 15px;
  padding: 5px 7px;
  padding-top: 10px;
`;

const Label = styled.span`
  color: white;
  font-size: 15px;
  padding: 2px 8px;
  white-space: nowrap;
  display: flex;
  width: 100%;
  box-sizing: border-box;
`;

const Progress = styled.div<{ total: number }>`
  width: 100%;
  box-sizing: border-box;
  padding: 5px 10px;
  display: flex;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    position: absolute;
    inset: 0;
    background: url(${loadingBarBg});
    background-repeat: round;
  }

  &::after {
    content: "";
    width: calc(100% - ${(props) => props.total}%);
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: var(--darkFive);
  }
`;

const getTotal = (start: string) => {
  const months = differenceInMonths(new Date(), new Date(start));
  return {
    years: ~~(months / 12),
    months: ~~(months % 12)
  };
};

export const Experiences = ({ items }: IProps) => {
  const showMonths = false;
  const highest = getTotal(items[0].start);

  return (
    <Element>
      {items.map((item, i) => {
        let { years, months } = getTotal(item.start);
        const total = (years / highest.years) * 100;
        return (
          <Experience key={item.name}>
            <Label style={{ justifyContent: "space-between" }}>
              <span>{item.name}</span>
              <span style={{ marginLeft: "auto" }}>
                {years} Years {showMonths && <span>{months > 0 ? `${months} mo` : ""}</span>}
              </span>
            </Label>
            <Progress total={total}></Progress>
          </Experience>
        );
      })}
    </Element>
  );
};
