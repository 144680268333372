import { createGlobalStyle } from "styled-components/macro";

export const GlobalStyle = createGlobalStyle`
    * {
      margin: 0;
    }

    :root {
      --darkOne: #181818;
      --darkTwo: #202020;
      --darkThree: #2c2c2c;
      --darkFour: #363636;
      --darkFive: #606060;

      --primary: #6460ff;
      --secondary: #5372ff;

      --textColor: #f0f0f0;

      --containerSize: 900px;
      --sectionSize: 650px;
    }

    html {
      background: var(--darkOne);
    }

    @font-face {
      font-family: "Helvetica Neue";
      src: url("fonts/HelveticaNeue.ttf");
    }

    @font-face {
      font-family: "The Bold Font";
      src: url("fonts/theboldfont.ttf");
    }

    body {
      margin: 0;
      padding: 80px 0;
      font-family: "Helvetica Neue";
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #f0f0f0;
    }

    a {
      color: var(--primary);
      text-decoration: none;
    }

    * {
      scrollbar-width: auto;
      scrollbar-color: #7234e5 #454545;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      height: 7px;
      width: 7px;
    }

    *::-webkit-scrollbar-track {
      background: #272727;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #7234e5;
      border-radius: 15px;
      border: 0px solid #050505;
    }
`;
