import ReactDOM from "react-dom/client";
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Fragment } from "react";
import { GlobalStyle } from "./components/GlobalStyle";
import { Palia } from "./pages/Palia";
import { Lol } from "./pages/Lol";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Fragment>
    <GlobalStyle />
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/palia" element={<Palia />} />
        <Route path="/lol" element={<Lol />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  </Fragment>
);
