import styled from "styled-components/macro";

export const Section = styled.div<{ marginTop?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${(p) => p.marginTop || "30px"};
  padding-bottom: 30px;
`;
