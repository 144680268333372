import { useCallback, useEffect, useState } from "react";
import beep from "../assets/sounds/tuturu.mp3";

const Timestamp = ({ values }: { values: any[] }) => {
  const pad = (value: any) => String(value).padStart(2, "0");
  return <>{values.map((e) => pad(e)).join(":")}</>;
};

export const Palia = ({ targetMinute = 15 }) => {
  const calculateMissingMinutes = useCallback((min: number) => (min < 15 && min > 0 ? targetMinute - min - 1 : 60 - min + targetMinute), []);

  const [date] = useState(new Date());
  const [lastHour, setLastHour] = useState<number>();
  const [hours, setHours] = useState<number>(date.getHours());
  const [minutes, setMinutes] = useState<number>(date.getMinutes());
  const [seconds, setSeconds] = useState<number>(date.getSeconds());
  const [missing, setMissing] = useState<number | string>(calculateMissingMinutes(minutes));
  const [mute, setMute] = useState(false);

  useEffect(() => {
    const alarm = new Audio(beep);
    const intervalId = setInterval(() => {
      const date = new Date();
      setHours(date.getHours());
      setMinutes(date.getMinutes());
      setSeconds(date.getSeconds());
      setMissing(calculateMissingMinutes(minutes));

      if (minutes === targetMinute && hours !== lastHour) {
        setLastHour(hours);
        if (!mute) alarm.play();
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [calculateMissingMinutes, hours, lastHour, minutes, mute, setHours, setMinutes, targetMinute]);

  return (
    <>
      <p>
        <Timestamp values={[hours, minutes, seconds]} />
      </p>
      <p>
        Next timer in: <Timestamp values={[missing, 60 - seconds]} />
      </p>
      <p>
        <input id="mute" type="checkbox" checked={mute} onClick={() => setMute(!mute)} />
        <label htmlFor="mute"> Mute?</label>
      </p>
    </>
  );
};
