import styled from "styled-components/macro";
import { media } from "../components/Media";
import badge from "../assets/img/badge.png";
import baltaIO from "../assets/img/certifications/balta.io.jpg";
import coursera from "../assets/img/certifications/coursera.jpg";
import udemy from "../assets/img/certifications/udemy.jpg";

const Element = styled.div`
  font-size: 14px;
  max-width: 645px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const Item = styled.div`
  position: relative;
  display: flex;
  background: var(--darkThree);
  flex-direction: row;
  padding: 10px 5px;
  box-sizing: border-box;
  flex: 0 0;
  flex-basis: calc(100% - 5px);
  align-items: center;
  ${media.desktop`
  flex-basis: calc(50% - 5px);
  cursor: pointer;
`}

  .name {
    font-weight: bold;
    margin-bottom: 0.2em;
    color: var(--secondary);
  }

  .id {
    font-size: 10px;
    display: inline;
    margin-left: 10px;
  }
`;

const Image = styled.div<{ image: any; autoMargin: boolean }>`
  flex: ${(p) => (p.autoMargin ? "0 0 90px" : "0 0 75px")};
  height: ${(p) => (p.autoMargin ? "90px" : "75px")};
  background-image: url(${(p) => p.image});
  background-size: 100%;
  margin-right: ${(p) => (p.autoMargin ? "5px" : "13px")};
  margin-left: ${(p) => (p.autoMargin ? "0px" : "8px")};
`;

const Wrapper = styled.div``;

const Name = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;
const Company = styled.div`
  margin-bottom: 5px;
`;
const Date = styled.div``;
const Id = styled.div``;

const CertificateIcon = styled.img`
  width: 25px;
  display: block;
  position: absolute;
  right: 5px;
  bottom: 5px;
  content: url(${badge});
`;

export const Certifications = () => {
  const certifications = [
    {
      image: "https://images.credly.com/size/90x90/images/a12fff38-aab2-4643-be27-7e5c39ddc75c/image.png",
      name: "AWS Partner: Sales Accreditation (Business)",
      company: "Amazon Web Services (AWS)",
      date: "Apr 2023",
      id: "0bc66829-681f-474c-b0a7-049906aafd1a",
      link: "https://www.credly.com/badges/0bc66829-681f-474c-b0a7-049906aafd1a",
      autoMargin: true
    },
    {
      image: "https://images.credly.com/size/90x90/images/81f903ed-c3a1-4f4b-afcd-e03331a5b12c/image.png",
      name: "AWS Partner: Accreditation (Technical)",
      company: "Amazon Web Services (AWS)",
      date: "Feb 2023",
      id: "3406c2d8-673a-495d-82fe-98369082a6e1",
      link: "https://www.credly.com/badges/3406c2d8-673a-495d-82fe-98369082a6e1",
      autoMargin: true
    },
    {
      image: "https://images.credly.com/size/90x90/images/ee35f7c5-696e-47ca-895c-960dfba108b3/image.png",
      name: "AWS Partner: Cloud Economics Accreditation",
      company: "Amazon Web Services (AWS)",
      date: "Feb 2023",
      id: "bf69712d-f19f-4e6b-a8c3-a3b3bc3e820d",
      link: "https://www.credly.com/badges/bf69712d-f19f-4e6b-a8c3-a3b3bc3e820d",
      autoMargin: true
    },

    {
      image: baltaIO,
      name: "Criando um App com React, TypeScript, Hooks, Context e Forms",
      company: "balta.io",
      date: "Dec 2021",
      id: "f4f953d5-669c-4e36-90fa-539c63886f87",
      link: "https://balta.io/certificados/f4f953d5-669c-4e36-90fa-539c63886f87"
    },

    {
      image: udemy,
      name: "Construindo Aplicações Web Com o Novo Angular (4, 5 e 6)",
      company: "Udemy",
      date: "Feb 2018",
      id: "UC-Q6OMBAEK",
      link: "https://udemy-certificate.s3.amazonaws.com/pdf/UC-Q6OMBAEK.pdf"
    },

    {
      image: coursera,
      name: "Como criar jogos 2D para iPhone e iPad",
      company: "Coursera Course Certificates",
      date: "Feb 2016",
      id: "W3FVZ6P4SKDX",
      link: "https://www.coursera.org/account/accomplishments/verify/W3FVZ6P4SKDX"
    },

    {
      image: coursera,
      name: "Como criar um aplicativo para iPhone",
      company: "Coursera Course Certificates",
      date: "Feb 2016",
      id: "48EW5Y3G5YBF",
      link: "https://www.coursera.org/account/accomplishments/verify/48EW5Y3G5YBF"
    },

    {
      image: coursera,
      name: "Como aprimorar e monetizar seu aplicativo para iOS e Apple Watch",
      company: "Coursera Course Certificates",
      date: "Jan 2016",
      id: "3SK9XACBEVWK",
      link: "https://www.coursera.org/account/accomplishments/verify/3SK9XACBEVWK"
    },

    {
      image: coursera,
      name: "Como criar aplicativos com múltiplas telas para iPhone e iPad",
      company: "Coursera Course Certificates",
      date: "Nov 2015",
      id: "UUNMARZA3B64",
      link: "https://www.coursera.org/account/accomplishments/verify/UUNMARZA3B64"
    }
  ];

  return (
    <Element>
      {certifications.map((c) => (
        <Item key={c.name} onClick={() => window.open(c.link)}>
          <Image image={c.image} autoMargin={!!c.autoMargin} />
          <Wrapper>
            <Name>{c.name}</Name>
            <Company>{c.company}</Company>

            <Date>
              {c.date}
              {/* <Id>{c.id}</Id> */}
            </Date>
            <CertificateIcon />
          </Wrapper>
        </Item>
      ))}
    </Element>
  );
};
