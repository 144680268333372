import styled from "styled-components/macro";
import { media } from "../components/Media";
import { Link } from "react-scroll";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export interface ICategorie {
  name: string;
  scrollTo?: string;
  url?: string;
  subcategories?: ICategorie[];
}

const Hamburguer = styled(FontAwesomeIcon)`
  display: block;
  align-self: self-end;
  margin-right: 15px;
  font-size: 35px;
  padding: 15px 10px;
  ${media.tablet`
    display: none;
  `}
`;

const Element = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  top: 0;
  cursor: pointer;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  background: var(--darkTwo);
  ${media.desktop`
    overflow: hidden;
    justify-content: center;
  `}
`;

const MenuItens = styled.div<{ isOpen?: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  color: white;
  z-index: 10;
  overflow: hidden;
  transition: max-height 0.3s linear;

  max-height: ${(p) => (p.isOpen ? "100vh" : "0px")};

  ${media.tablet`
  flex-direction: row;
  max-height: 100vh;
`}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Item = styled(Link)`
  display: block;
  font-weight: bold;
  position: relative;
  font-size: 18px;
  padding: 15px 15px;
  border-bottom: solid 3px transparent;
  color: #e7e7e7;

  &:hover {
    background: var(--primary);
  }

  &.active {
    border-bottom: solid 3px var(--primary);
  }
`;

interface IProps {
  items?: ICategorie[];
}

export const Menu = ({ items }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Element>
        <Hamburguer onClick={() => setIsOpen(!isOpen)} icon={faBars} />
        <MenuItens isOpen={isOpen}>
          {items?.map((item, index) => (
            <Wrapper key={item.name}>
              <Item activeClass="active" to={item.scrollTo || ""} offset={-150} spy={true} smooth={true} duration={500}>
                {item.name}
              </Item>
              {/* {index < items.length - 1 && "|"} */}
            </Wrapper>
          ))}
        </MenuItens>
      </Element>
    </>
  );
};
