import differenceInYears from "date-fns/differenceInYears";
import styled from "styled-components/macro";
import { Profile } from "./Profile";
import { media } from "../components/Media";
import { Title } from "../components/Title";
import { PreviewLink } from "../components/PreviewLink";
import brazilFlag from "../assets/img/brazil-flag.png";
import brazil from "../assets/img/brazil.png";
import campinas from "../assets/img/campinas.png";
import encora from "../assets/img/encora.png";

const Element = styled.div`
  margin-top: 70px;
  max-width: calc(var(--sectionSize) + 100px);
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
  ${media.desktop`
    flex-direction: row;
    align-items: center;
  `};
`;

const AboutWrapper = styled.div`
  flex: 1 1;
`;

const AboutTitle = styled(Title)`
  text-align: left;
  font-size: 20px;
`;

const Text = styled.div`
  font-size: 17px;
  background: var(--darkFour);
  padding: 15px 19px;

  a {
    text-decoration: underline;
    font-weight: bold;
  }
`;

const age = differenceInYears(new Date(), new Date("05/25/1992"));

export const About = () => {
  return (
    <Element>
      <AboutWrapper>
        <AboutTitle className="subTitle" primary margin="0 0 22px">
          About Me
        </AboutTitle>
        {/* prettier-ignore */}
        <Text>
          <p>My name is <b>Jefferson</b> but you can call me <b>Jeff</b> or <b>xJeeh</b> 😊</p>
          <br />
          <p>I'm a {age} years old Software Engineer who lives in <PreviewLink img={campinas} target="_blank" text="Campinas" href="https://wikipedia.org/wiki/Campinas" />, a city near São Paulo, in <PreviewLink img={brazil} target="_blank" href="https://wikipedia.org/wiki/Brazil" text="Brazil" />. <img width="23px" src={brazilFlag} alt="brazil flag"/></p>
          <br />
          <p>A front-end enthusiast with focus on web and mobile-hybrid software development.</p>
          <p>Nerd in love with the trinity HTML-CSS-JS, coffee and games.</p>
          <br />
          <p>Currently I'm a full time employee at <PreviewLink img={encora} target="_blank" href="https://www.encora.com" text="Encora Inc."/></p>
        </Text>
      </AboutWrapper>
      <Profile trusty={100} cool={80} sexy={15} />
    </Element>
  );
};
