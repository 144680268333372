import { faLinkedin, faDiscord, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faCoffee, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

const Element = styled.div`
  display: flex;
  gap: 5px;
  font-size: 1.2em;
  align-items: center;
  background: var(--darkThree);
  padding: 10px 25px;
`;

const Item = styled.a`
  padding: 0px 8px;
  color: white;
  transition: all 0.3s;
  padding: 6px 9px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.5s ease;
  font-size: 25px;
  &:hover {
    color: var(--primary);
    background: white;
    border-radius: 80px;
  }
`;

export const Contact = () => {
  return (
    <Element>
      <Item title="Email" target="_blank" rel="noreferrer" href="mailto:xjeehz@gmail.com">
        <FontAwesomeIcon icon={faEnvelope} />
      </Item>
      <Item title="Linkedin" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/xjeeh">
        <FontAwesomeIcon icon={faLinkedin} />
      </Item>
      <Item title="Discord" target="_blank" rel="noreferrer" href="https://discordapp.com/users/201351221133574149">
        <FontAwesomeIcon icon={faDiscord} />
      </Item>
      <Item title="GitHub" target="_blank" rel="noreferrer" href="https://github.com/xjeeh">
        <FontAwesomeIcon icon={faGithub} />
      </Item>
      <Item title="BuyMeACoffee" target="_blank" rel="noreferrer" href="https://www.buymeacoffee.com/xjeeh">
        <FontAwesomeIcon icon={faCoffee} />
      </Item>
    </Element>
  );
};
