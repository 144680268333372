import { css } from "styled-components/macro";

const breakpoints = {
  uhd: 1980,
  widescreen: 1366,
  desktop: 1024,
  tablet: 768
};

export const media = Object.keys(breakpoints).reduce((acc: { [key: string]: (...args: any) => any }, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label as keyof typeof breakpoints]}px) {
      ${css(...(args as [any]))};
    }
  `;
  return acc;
}, {});
