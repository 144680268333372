import styled from "styled-components/macro";

export const Container = styled.div`
  min-height: 100vh;
  max-width: var(--containerSize);
  background: var(--darkTwo);
  margin: auto;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--textColor);
`;
