import styled from "styled-components/macro";

const Link = styled.a`
  position: relative;

  img {
    pointer-events: none;
    height: 0;
    width: 0;
    position: absolute;
    bottom: 22px;
    left: 0px;
    transition: 0.5s all ease;
    opacity: 0;
    z-index: 10;
  }

  &:hover {
    img {
      opacity: 1;
      width: 370px;
      height: 310px;
    }
  }
`;

interface IProps {
  target: string;
  href: string;
  text: string;
  img: any;
}

export const PreviewLink = ({ target, href, text, img }: IProps) => {
  return (
    <Link target={target} href={href} rel="noreferrer">
      <img src={img} alt={text} />
      {text}
    </Link>
  );
};
