import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { animateScroll } from "react-scroll";
import { useCallback, useEffect, useState } from "react";

const Element = styled.div`
  cursor: pointer;
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0.5em;
  padding: 0.5em 0.7em;
  font-size: 2em;
  border-radius: 5em;
  user-select: none;
  animation: bounceUp 2s infinite;

  @keyframes bounceUp {
    0% {
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: translate3d(0, -0.5em, 0);
    }
  }
`;

export const ScrollTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const listenToScroll = useCallback(() => {
    let heightToHideFrom = 200;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    setIsVisible(winScroll > heightToHideFrom);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [listenToScroll]);

  return !isVisible ? (
    <></>
  ) : (
    <Element onClick={animateScroll.scrollToTop}>
      <FontAwesomeIcon icon={faArrowUp} />
    </Element>
  );
};
