import styled from "styled-components/macro";

const Element = styled.div`
  width: 100%;
  font-size: 14px;
  cursor: default;
`;

const Title = styled.div`
  margin-bottom: 5px;
  display: block;
  color: #b2b0ff;
  font-weight: bold;
`;

const Group = styled.div`
  background: var(--darkThree);
  padding: 10px;
`;

const SubGroup = styled.div`
  background: var(--darkFour);
  font-size: 15px;
  padding: 10px;
  margin-top: 0.2em;
`;

const Item = styled.p`
  margin-bottom: 4px;
  font-size: 15px;
`;

export const SKills = () => {
  return (
    <Element>
      <div>
        <Group>
          <Title>Technologies</Title>
          <SubGroup>
            <Title>Front-End</Title>
            <div>
              <Item>JS + TypeScript 💙</Item>
              <Item>React + Next.js | Angular 2+</Item>
              <Item>Svelte | Angular.js</Item>
              <Item>SASS | CSS3 | Styled-Components</Item>
              <Item>HTML5</Item>
            </div>
          </SubGroup>
          <SubGroup>
            <Title>Back-End</Title>
            <div>
              <Item>Node.js + Express.js</Item>
              <Item>Python, C#, PHP</Item>
            </div>
          </SubGroup>
          <SubGroup>
            <Title>Database</Title>
            <div>
              <Item>PostgreSQL | MySQL | SQLServer </Item>
              <Item>NoSQL: GraphQL | MongoDB</Item>
              <Item>Redis</Item>
            </div>
          </SubGroup>
          <SubGroup>
            <Title>Common</Title>
            <div>
              <Item>AWS Services</Item>
            </div>
          </SubGroup>
        </Group>
        <Group>
          <Title>Soft Skills</Title>
          <SubGroup>Leadership, Ownership, Communication</SubGroup>
        </Group>
      </div>
    </Element>
  );
};
